import React from 'react';
import { path } from 'ramda';
import { Link } from 'react-router-dom';
import HamburgerMenu from './HamburgerMenu';

export default function Header({ header, widgets, menu }) {
    const image = path(['Logo', 'data', 'attributes', 'url'], header) || '/images/logo.svg';
    const phone = path(['Phone'], header) || '8-800-500-08-79';
    const phoneHref = `${phone}`.replace(/\-/g, '');

    return (
        <>
            <header className="flex items-center fixed top-0 left-0 right-0 z-[1200] max-lg:p-5 lg:py-[30px] bg-white after:shadow-[0_4px_15px_0_rgba(0,0,0,0.07)]">
                <div className="flex w-full max-lg:px-0 container justify-between">
                    <div className='flex gap-[15px]'>
                        <Link to="/">
                            <img className='max-md:h-[34px] md:max-lg:h-[42px]' src={image} alt="METRO" />
                        </Link>
                        { widgets && widgets.length ? (
                            <div className='max-md:hidden flex gap-[15px]'>
                                { widgets.map((item, index) => !!item.Script ? (
                                    <div
                                        key={index}
                                        className='md:max-lg:h-[42px] lg:h-12 overflow-hidden'
                                        dangerouslySetInnerHTML={{ __html: item.Script }}
                                    />
                                ) : (
                                    item.Image && (
                                        <div key={index} className='md:max-lg:h-[42px] lg:h-12 overflow-hidden'>
                                            <a href={item.Href} target="_blank" rel="noreferrer">
                                                <img className='h-full' src={ path(['Image', 'data', 'attributes', 'url'], item) } alt={item.Href}/>
                                            </a>
                                        </div>
                                    )
                                ))}
                            </div>
                        ) : null}
                    </div>
                    <div className='flex gap-2.5 justify-center pl-3 lg:pl-[30px] max-w-full'>
                        <a href={`tel:${phoneHref}`} className='btn btn-outline h-auto flex-1'>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="none"
                                viewBox="0 0 16 16"
                                >
                                <path
                                fill="#1A3C7B"
                                d="M15.661 12.39c-.217-.243-.884-.847-1.361-1.206-.469-.369-1.237-.867-1.536-1.021-.488-.255-1.224-.226-1.681.081-.376.261-.724.56-1.039.893l-.007.007a1.054 1.054 0 01-1.357.149A14.956 14.956 0 016.51 9.49 14.959 14.959 0 014.706 7.32a1.055 1.055 0 01.149-1.357l.007-.007c.332-.315.632-.663.893-1.039.307-.457.336-1.193.082-1.681-.155-.299-.653-1.066-1.022-1.536C4.456 1.223 3.853.556 3.611.34 3.215-.017 2.548-.11 2.08.144A6.351 6.351 0 00.989.929L.953.96C-1.107 2.732.249 7.68 4.288 11.71c4.033 4.04 8.979 5.397 10.75 3.337l.032-.035c.3-.336.563-.703.785-1.094.255-.465.162-1.133-.194-1.529z"
                                ></path>
                            </svg> <span className='max-lg:hidden whitespace-nowrap'>{phone}</span>
                        </a>
                        <HamburgerMenu menu={menu} />
                    </div>
                </div>
            </header>
            <div className="max-md:h-[74px] md:max-lg:h-[82px] lg:h-[108px]" />
        </>
    )
}
