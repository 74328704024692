import React, { Component } from 'react';
import { Select } from 'antd';

import 'antd/lib/select/style/index.css';

import withFieldWrapper from '../../hocs/withFieldWrapper';
import withLoad from '../../hocs/withLoad';

export class SelectComponent extends Component {
    render() {
        const { input: { name, value }, onChange } = this.props;
        const { options = [], valueName = 'id' } = this.props;
        const { allowClear, showSearch = true, disabled, loading, placeholder } = this.props;
        const { open, onDropdownVisibleChange, noBindContainer } = this.props;

        return (
            <div ref={node => this.container = node}>
                <Select
                    open={open}
                    disabled={disabled}
                    loading={loading}
                    value={loading ? null: value}
                    onChange={onChange}
                    onDropdownVisibleChange={onDropdownVisibleChange}
                    allowClear={allowClear}
                    notFoundContent={loading ? 'Загрузка...' : 'Список пуст'}
                    placeholder={placeholder}
                    showSearch={showSearch ? options && (options.length > 5) : showSearch}
                    getPopupContainer={noBindContainer ? undefined : () => this.container}
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                >
                    { (options && options.length ? options : []).map(option =>
                        <Select.Option
                            key={`${name}-${option.id || option}`}
                            value={option[valueName] || option}
                            data={option.data}
                        >
                            { option.name || `${option}` }
                        </Select.Option>
                    )}
                </Select>
            </div>
        );
    }
}

export const LoadSelect = withLoad(withFieldWrapper(SelectComponent));

export default withFieldWrapper(SelectComponent);
