
import React, { Fragment } from 'react';
import { useQuery } from 'react-query';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { assoc, path, pathOr } from 'ramda';
import Alert from 'antd/lib/alert';
import List from 'antd/lib/list';
import Spin from 'antd/lib/spin';

import { getVacancies } from '../../queries';
import { LINES_PER_PAGE } from '../../constants/list';
import { getUrlParamsString } from '../../utils/filter';
import { scrollToTop } from '../../utils/scroll';
import { getVacancyTags } from '../../utils/vacancy';

const spinDelay = 400;

const VacanciesList = ({ params, filters = {}, line, centralOffice, objtype }) => {
    const { data, status } = useQuery(['vacancies', JSON.stringify({ filters, line, centralOffice, objtype })], getVacancies);
    const vacancies = pathOr([], ['vacancy'], data);
    const navigate = useNavigate();
    const location = useLocation();

    const changePagination = (page) => {
        scrollToTop(400);

        navigate(`${location.pathname}${getUrlParamsString(assoc('line', (page - 1) * LINES_PER_PAGE, params))}`)
    };

    const getPaginationConfig = () => {
        return {
            onChange: changePagination,
            total: pathOr(0, ['linesTotal'], data),
            pageSize: LINES_PER_PAGE,
            hideOnSinglePage: true,
            current: (line / LINES_PER_PAGE) + 1,
            showSizeChanger: false
        };
    };

    if (status === 'success') {
        return !vacancies.length ? (
            <Fragment>
                <Alert
                    message="Мы рады, что вас заинтересовала работа в нашей компании."
                    description= {'На данный момент по вашему запросу нет открытых вакансий. Следите за обновлениями на нашем сайте.'}
                    type="info"
                    showIcon
                />
            </Fragment>
        ) : (
            <Spin spinning={status === "loading"} delay={spinDelay}>
                <List
                    className='vacancies-list'
                    itemLayout="horizontal"
                    dataSource={vacancies}
                    loading={status === "loading"}
                    pagination={getPaginationConfig()}
                    renderItem={item => (
                        <List.Item>
                            <NavLink to={`/vacancy/${item.vacancyId}`} className="flex flex-col w-full max-md:p-5 md:p-[30px]">
                                <div className='pb-5 max-md:!text-[20px] md:max-lg:!text-[22px] lg:!text-[26px] !leading-[140%] text-black font-medium break-normal'>
                                    {item.vacancyName}
                                </div>
                                {item.description && <div className='text-xl font-normal leading-7'>{item.description}</div>}
                                <div className="gap-2.5 inline-flex text-black max-md:text-[16px] md:max-lg:text-[18px] lg:text-[20px] leading-[140%] mb-[30px]">
                                    <svg
                                        className='max-md:size-4 md:max-lg:size-5 max-lg:size-6 max-md:min-w-[16px] md:max-lg:min-w-[20px] lg:min-w-[24px]'
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        >
                                        <path
                                            fill="#1A3C7B"
                                            d="M18 4.48a8.485 8.485 0 00-12 12l5.27 5.28a1.002 1.002 0 001.42 0L18 16.43a8.45 8.45 0 000-11.95zM16.57 15L12 19.59 7.43 15a6.46 6.46 0 119.14 0zM9 7.41a4.32 4.32 0 000 6.1 4.31 4.31 0 007.36-3 4.24 4.24 0 00-1.26-3.05A4.3 4.3 0 009 7.41zm4.69 4.68a2.33 2.33 0 11.67-1.63 2.33 2.33 0 01-.72 1.63h.05z"
                                        ></path>
                                    </svg>
                                    {path(['metro', 'store'], item) || path(['metro', 'address'], item)}</div>
                                <div className="flex flex-wrap max-md:gap-[13px] md:gap-[15px]">
                                    {getVacancyTags(item).map(({ tag }, index) => tag && (
                                        <span key={index} className="bg-blue p-2.5 max-md:text-[12px] md:max-lg:text-[14px] lg:text-[16px] text-white leading-[100%] font-medium rounded-10">{tag}</span>
                                    ))}
                                </div>
                            </NavLink>
                        </List.Item>
                    )}
                />
            </Spin>
        );
    } else if (status === "error") {
        return (
            <div className='vacancy-list-error text-center py-16 text-grey'>
                Ошибка при загрузке списка вакансий
            </div>
        );
    } else {
        return (
            <div className='vacancy-spin'>
                <Spin spinning={!vacancies.length && status === 'loading'} />
            </div>
        );
    }
}

export default VacanciesList;
