import React, { Component } from 'react';
import Modal from 'react-modal';

export default class OpdModal extends Component {
    render() {
        const { visible, onCancel } = this.props;

        return <Modal
            isOpen={visible}
            onRequestClose={onCancel}
            contentLabel='Согласие субъекта персональных данных на обработку персональных данных'
            size='large'
            footer={null}
            className='modal-opd'
        >
            <div className="modal-head max-md:pt-[44px] md:max-lg:pt-[64px] lg:pt-[74px]">
                <button className="absolute max-lg:left-5 lg:left-10 top-[30px]" onClick={onCancel}>
                    <svg
                        className='max-md:size-5'
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                        >
                        <path
                            stroke="#1A3C7B"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 12H5M12 5l-7 7 7 7"
                        ></path>
                    </svg>
                </button>
                <h2 className="text-black font-medium max-md:text-[16px] md:max-lg:text-[18px] lg:text-[26px] leading-[140%] max-md:pb-[15px] md:pb-[30px]">Согласие на обработку персональных данных</h2>
            </div>
            <div className="modal-body">
                <div className='text-base'>
                    <p>
                        Я даю согласие ООО «МЕТРО Кэш энд Керри», находящемуся по адресу: 125445, г. Москва, Ленинградское шоссе, д. 71 Г стр. 2;
                    </p>
                    <p>
                        и\или ООО «МГЛ МЕТРО Груп Логистикс», находящемуся по адресу: Московская область, Ногинский район, территория «Ногинск-Технопарк», д. 8,
                    </p>
                    <p>
                        далее – Оператору\Операторам на обработку моих персональных данных, включая  сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ),  трансграничную передачу, шифрование, обезличивание, блокирование, удаление, уничтожение с использованием средств автоматизации или без использования таких средств, а также передачу третьим лицам в строгом соответствии с обозначенными в настоящем Заявлении о согласии целями, под обязательство о соблюдении конфиденциальности и обеспечении надежной защиты персональных данных третьей стороной.
                    </p>
                    <p>
                        в целях:
                    </p>
                    <p>
                        включения во внешний кадровый резерв Оператора (ов) и последующего принятия решения о приёме, либо отказа в приёме на работу.
                    </p>
                    <p>
                        Мои персональные данные, в отношении которых дается данное согласие, включают:
                    </p>
                    <p>
                        фамилия, имя, отчество; дата рождения; паспортные данные; адрес; контактные номера телефонов и электронной почты; cведения об образовании, профессии и трудовой деятельности, а также другой информации, указанной мной в предоставленном резюме.
                    </p>
                    <p>
                        Даю согласие Оператору (ам) на получение рассылки информационного характера (ссылки на вакансии, интервью, др. ) посредством электронной почты, смс-сообщений, мессенджеров (Viber. WhatsApp, Skype, Telegram и проч.).
                    </p>
                    <p>
                        Согласие действует на период в течение 5 лет и может быть отозвано путем направления мной соответствующего запроса на адрес Оператора (ов).
                    </p>
                </div>
            </div>
        </Modal>;
    }
}
