import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { last, eqProps, append, remove, findIndex, propEq, isEmpty } from 'ramda';
import cx from 'classnames';
import Upload from 'rc-upload';
import message from 'rc-message';

import { uploadButtonType, DEFAULT_MAX_FILE_SIZE } from '../../../constants/files';
import withFieldWrapper from '../../hocs/withFieldWrapper';

function arrayBufferToBase64(buffer) {
   let binary = '';
   const bytes = new Uint8Array(buffer);
   const len = bytes.byteLength;

   for (let i = 0; i < len; i++) {
       binary += String.fromCharCode(bytes[i]);
   }

   return window.btoa(binary);
}

export default class FileInput extends Component {
    static propTypes = {
        input: PropTypes.object,
        meta: PropTypes.object,
        label: PropTypes.node,
        accept: PropTypes.string,
        documentName: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool,
        ]),
        documentType: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
    }

    state = {
        filename: '',
        fileList: [],
    }

    componentDidUpdate(prev) {
        const value = prev.input.value;
        const nextValue = this.props.input.value;

        if (this.props.multiple) {
            if (this.state.fileList.length && (!nextValue || isEmpty(nextValue))) {
                this.setState({
                    fileList: []
                });
            }
        } else {
            if (!eqProps('name', value, nextValue)) {
                this.setState({
                    filename: nextValue.name || ''
                });
            }
        }
    }

    onRemove = file => {
        const { multiple, input: { value }} = this.props;

        if (multiple) {
            const index = findIndex(propEq('uid', file.uid), this.state.fileList);

            this.props.input.onChange(remove(index, 1, value));
            this.setState(prev => ({
                filename: '',
                fileList: remove(index, 1, prev.fileList),
            }));
        } else {
            this.props.input.onChange(null);
            this.setState({
                filename: '',
                fileList: []
            });
        }
    }

    onChange = (file) => {
        const { input: { onChange, value }, documentType, documentName, multiple, maxDocFileSize } = this.props;

        if (file) {
            const maxFileSize = maxDocFileSize ? maxDocFileSize * 1048576 : DEFAULT_MAX_FILE_SIZE;
            const { size, name = 'Резюме' } = file;
            this.setState(prev => ({
                filename: name,
                fileList: multiple ? append(file, prev.fileList) : [file],
            }));

            if (size && (size > maxFileSize)) {
                onChange({ error: 'exceededAllowedSize' });
                return false;
            }

            const reader = new FileReader();
            reader.onload = () => {
                if (reader.result) {
                    const payload = {
                        name: documentName ? name : '',
                        docType: documentType,
                        fileType: last(name.split('.')),
                        body: arrayBufferToBase64(reader.result)
                    };

                    onChange(multiple ? append(payload, value || []) : payload);
                } else {
                    message.error('Не удалось обработать файл');
                }
            };
            reader.readAsArrayBuffer(file);
        }

        return false;
    }

    render() {
        const { accept, meta: { invalid, dirty, submitFailed, error }, multiple, uploadType, text } = this.props;
        const { fileList } = this.state;
        const hasError = invalid && (submitFailed || dirty);

        return (
            <>
                <div className={cx('form-group', { error: hasError })}>
                    <div className='file-upload-container flex flex-col gap-[5px]'>
                        <label className='max-md:text-[12px] md:text-[16px] leading-[120%] block'>Резюме</label>
                        { uploadType === uploadButtonType ? (
                            <Upload
                                className='line-upload md:inline-flex items-center'
                                accept={accept}
                                multiple={multiple}
                                onRemove={this.onRemove}
                                beforeUpload={this.onChange}
                                fileList={fileList}
                            >
                                <button className='btn btn-md btn-second max-md:w-full' type='button'>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="none"
                                        viewBox="0 0 16 16"
                                        >
                                        <path
                                            stroke="#1A3C7B"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M8 3.333v9.333M3.332 8h9.333"
                                        ></path>
                                    </svg>
                                    { text || 'Загрузить документ' }
                                </button>
                                {fileList?.length ? <>
                                    {(fileList || []).map(file => (
                                    <span key={file.uid} className='flex lg:inline-flex mt-4 lg:mt-0 items-center'>
                                        <span className='px-3'>{file.name}</span>
                                        <svg onClick={event => { event.stopPropagation(); this.onRemove() }} className='min-w-5' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.89503 17.5C5.40903 17.5 4.9957 17.33 4.65503 16.99C4.31503 16.6493 4.14503 16.236 4.14503 15.75V5.125H3.33203V3.375H7.54103V2.5H12.457V3.375H16.666V5.125H15.832V15.75C15.832 16.236 15.662 16.6493 15.322 16.99C14.9814 17.33 14.568 17.5 14.082 17.5H5.89503ZM14.082 5.125H5.89503V15.75H14.082V5.125ZM7.45703 14H9.20703V6.875H7.45703V14ZM10.77 14H12.52V6.875H10.77V14ZM5.89503 5.125V15.75V5.125Z" fill="#737373"/>
                                        </svg>
                                    </span>
                                ))}
                                </> : <>
                                    <div className="max-md:w-full max-md:mt-[15px] md:ml-[15px] text-blue opacity-40 text-base font-normal leading-tight">(Тип файла: pdf, doc, docx, txt, rtf)</div>
                                </>}
                            </Upload>
                        ) : (
                            <Upload.Dragger
                                accept={accept}
                                multiple={multiple}
                                onRemove={this.onRemove}
                                beforeUpload={this.onChange}
                                fileList={fileList}
                            >
                                <p className="ant-upload-drag-icon">
                                   FILE
                                </p>
                                <p className="ant-upload-text">
                                    Нажмите или перетащите файл в эту область для загрузки
                                </p>
                                <p className="ant-upload-hint">
                                    Поддерживается загрузка одного файла. Максимальный размер файла 32МБ.
                                </p>
                            </Upload.Dragger>
                        )}
                    </div>
                    { hasError && (
                        <div className='validation-message'>
                            <div className='validation-text'>{error}</div>
                        </div>
                    )}
                </div>
            </>
        );
    }
}

export const FileInputWithWrapper = withFieldWrapper(FileInput);
