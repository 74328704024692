export const vacancyTypes = [
    {
        id: 'office',
        name: 'Офис'
    },
    {
        id: 'store',
        name: 'Торговый центр'
    },
    {
        id: 'mgl',
        name: 'Склад'
    }
];

export const internalEmploymentType = [
    {
        value: "permanentJob",
        name: 'Постоянная работа'
    }, {
        value: "temporary",
        name: 'Временная работа'
    },
];

export const internalWorkSchedule = [
    {
        value: 'fullDay',
        name: 'Полный рабочий день'
    },
    {
        value: 'shiftWork',
        name: 'Сменный график'
    },
    {
        value: 'partTime',
        name: 'Неполный рабочий день'
    },
];

export const internalWorkExperience = [
    {
        value: "experienced",
        name: 'С опытом работы'
    },
    {
        value: "noExperience",
        name: 'Без опыта работы'
    },
];
