import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import { slide as Menu } from 'react-burger-menu';
import { path } from 'ramda';

import { defaultIcons } from './Footer';

const defaultMenu = [
    { Title: 'КАК ОТКЛИКНУТЬСЯ НА ВАКАНСИЮ', Link: '/respond' },
    { Title: 'ПРОЦЕДУРА ВНУТРЕННЕГО РЕКРУТМЕНТА', Link: '/recruitment' },
    { Title: 'ПОЛИТИКА ПО ПОДБОРУ ПЕРСОНАЛА', Link: '/policy' },
];

export default function HamburgerMenu({ menu }) {
    const location = useLocation();
    const [isOpen, setOpen] = useState(false);

    const onOpen = () => setOpen(!isOpen);
    const onClose = () => setOpen(false);

    useEffect(() => {
        if (isOpen) {
            onClose();
        }
    }, [location.key]);

    const title = path(['Title'], menu) || 'Горячая линия Metro';
    const phone = path(['Phone'], menu) || '8-800-500-08-79';
    const phoneLink = `${phone}`.replace(/\-/g, '');
    const schedule = path(['Schedule'], menu) || 'Будни: с 7 до 22 часов<br />Выходные: с 8 до 22 часов';
    const links = path(['Menu'], menu) || defaultMenu;
    const icons = path(['Icons'], menu) || defaultIcons;

    return (
        <>
            <Menu isOpen={isOpen} onOpen={onOpen} onClose={onClose} width={ 298 } bodyClassName={ "menu-open" } crossClassName={ "menu-burger" } right>
            <div className="flex flex-col">
                    {/* <div className="md:hidden flex gap-5">
                        { widgets.map((item, index) => !!item.Script ? (
                                <div
                                    key={index}
                                    className='max-md:h-[34px] overflow-hidden'
                                    dangerouslySetInnerHTML={{ __html: item.Script }}
                                />
                            ) : (
                                item.Image && (
                                    <div key={index} className='max-md:h-10 overflow-hidden'>
                                        <a href={item.Href} target="_blank" rel="noreferrer">
                                            <img className='h-full' src={ path(['Image', 'data', 'attributes', 'url'], item) } alt={item.Href}/>
                                        </a>
                                    </div>
                                )
                            ))}
                    </div> */}
                    <ul className='flex flex-col max-md:mt-[30px]'>
                        { links.map(item => (
                            <li key={item.Link}>
                                <Link to={item.Link} className="inline-flex uppercase max-md:text-sm font-medium md:text-base max-lg:mb-5 lg:mb-[30px]">
                                    {item.Title}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <div>
                    <span className='text-black max-md:text-sm lg:text-base uppercase'>{title}</span>
                    <div className='max-lg:pt-2.5 lg:pt-4'>
                        <a href={`tel:${phoneLink}`} className='font-bold max-lg:text-sm lg:text-base text-dark'>{phone}</a>
                        <div className='max-md:text-xs md:text-base text-black max-lg:pt-2.5 lg:pt-4 max-md:max-w-[150px] md:max-w-[200px]' dangerouslySetInnerHTML={{ __html : schedule }} />
                        <ul className="flex max-lg:pt-[30px] lg:pt-10">
                            { (icons || []).map((item, index) => (
                                <li className="pr-[15px]" key={index}>
                                    <a href={item.Link} target='_blank' className='hover:opacity-90' rel="noreferrer">
                                        <img src={path(['Image', 'data', 'attributes', 'url'], item)} alt={item.Title} />
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </Menu>
        </>
    )
}
