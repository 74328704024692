import { isEmpty, path } from 'ramda';

export const rangePickerValidator = (value = [], allValues) => {
    const attachments = path(['attachments', 0, 'body'], allValues);

    if (attachments) {
        return undefined;
    }

    if (isEmpty(value)) {
        return 'Это поле обязательно';
    }

    if(!value[2]) {
        return value[0] && value[1] ? undefined : 'Необходимо заполнить оба поля';
    } else {
        return value[0] ? undefined : 'Необходимо заполнить дату начала работы';
    }
};

export const workExperienceOrganizationValidator = (value, allValues) => {
    const attachments = path(['attachments', 0, 'body'], allValues);

    if (attachments) {
        return undefined;
    } else if (!value) {
        return 'Это поле обязательно';
    }
}

export const workExperiencePositionValidator = (value, allValues) => {
    const attachments = path(['attachments', 0, 'body'], allValues);

    if (attachments) {
        return undefined;
    } else if (!value) {
        return 'Это поле обязательно';
    }
}
