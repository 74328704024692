import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DatePicker } from 'antd';

import 'antd/lib/date-picker/style/index.css';

import withFieldWrapper from '../../hocs/withFieldWrapper';
import { API_DATE_FORMAT, DATE_FORMAT } from '../../../constants/dateFormats';

class DatePickerComponent extends Component {
    static propTypes = {
        input: PropTypes.object,
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
    };

    static defaultProps = {
        placeholder: 'дд.мм.гггг',
    }

    onChange = date => {
        const value = date ? date.format(API_DATE_FORMAT) : '';

        this.props.onChange(value);
    }

    disableFutureDates = current => {
        return current && current > moment().endOf('day');
    }

    render() {
        const { input, disableFutureDates, placeholder } = this.props;

        return <div ref={node => this.containerRef = node}>
            <DatePicker
                value={input.value ? moment(input.value, API_DATE_FORMAT) : null}
                className='ant-input date-masked-input'
                format={DATE_FORMAT}
                placeholder={placeholder}
                onChange={this.onChange}
                getPopupContainer={() => this.containerRef}
                disabledDate={disableFutureDates ? this.disableFutureDates : undefined}
            />
        </div>;
    }
}

export default withFieldWrapper(DatePickerComponent);
