import { VALUTA_LOGO } from '../constants/dictionaries';

export const toMoneyFormat = num => (+num).toLocaleString().replace(/,/g, ' ');

export const getVacancyTags = (vacancy = {}) => {
    const { salaryMin, salaryMax, salaryValuta } = vacancy;
    const valuta = VALUTA_LOGO[salaryValuta] || '₽';
    const { needExperience, typeOfEmployment, chartOfWork } = vacancy;

    return [
        {
            tag: salaryMin || salaryMax ?
                (salaryMin ? ` от ${toMoneyFormat(salaryMin)} ${valuta}` : salaryMax ? ` до ${toMoneyFormat(salaryMax)} ${valuta}` : '')
                : ''
        },
        {
            tag: needExperience ? `${needExperience}` : ''
        },
        {
            tag: typeOfEmployment ? `${typeOfEmployment}` : ''
        },
        {
            tag: chartOfWork ? `${chartOfWork}` : ''
        }
    ];
};
