
import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';

import { formItemLayout } from '../../../constants/forms';
import Input from '../formComponents/Input';

export default class ContactFields extends Component {
    render() {
        return <Fragment>
            <div className='lg:flex lg:mx-[-10px]'>
                <div className='lg:px-[10px] w-full'>
                    <Field
                        name='phone'
                        component={Input}
                        label='Телефон'
                        placeholder='+'
                        format={value => value ? '+' + value.replace(/[^-0-9]/gim, '') : null}
                        parse={value => value ? value.replace('+', '') : ''}
                        maxLength={14}
                        required
                        formItemLayout={formItemLayout} />
                </div>
                <div className='lg:px-[10px] w-full'>
                    <Field
                        name='email'
                        component={Input}
                        label='Email'
                        type='email'
                        required
                        formItemLayout={formItemLayout} />
                </div>
            </div>
        </Fragment>
    }
}
