import React, { Component } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';

import RangePicker from '../formComponents/RangePicker';
import Input from '../formComponents/Input';
import { formItemLayout } from '../../../constants/forms';
import { rangePickerValidator, workExperienceOrganizationValidator, workExperiencePositionValidator } from '../../../utils/validator';

export default class WorkExperienceFields extends Component {
    renderFields = ({ fields }) => {
        return <div>
            { fields.map((name, index) =>
                <div key={`place-${index}`}>
                    <div className='form-item-width'>
                        <Field
                            name={`${name}.begin`}
                            component={RangePicker}
                            label='Период работы'
                            formItemLayout={formItemLayout}
                            placeholder={['с', 'по']}
                            required
                            validate={rangePickerValidator} />
                    </div>
                    <div className='lg:flex lg:mx-[-10px]'>
                        <div className='lg:px-[10px] w-full'>
                            <Field
                                name={`${name}.organization`}
                                component={Input}
                                label='Название организации'
                                formItemLayout={formItemLayout}
                                required
                                validate={workExperienceOrganizationValidator} />
                        </div>
                        <div className='lg:px-[10px] w-full'>
                            <Field
                                name={`${name}.position`}
                                component={Input}
                                label='Должность'
                                required
                                formItemLayout={formItemLayout}
                                validate={workExperiencePositionValidator} />
                        </div>
                    </div>
                    { fields.length > 1 &&
                        <div className='mb-10'>
                            <button className='btn btn-sm btn-danger' onClick={() => fields.remove(index)}>
                                Удалить место работы
                            </button>
                        </div>
                    }
                </div>
            )}
            <div>
                <p>
                    <button className='btn btn-md' type="dashed" onClick={() => fields.push({})}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 16 16"
                            >
                            <path
                                stroke="#1A3C7B"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M8 3.333v9.333M3.332 8h9.333"
                            ></path>
                        </svg>
                        Добавить еще
                    </button>
                </p>
            </div>
        </div>;
    };

    render() {
        return <FieldArray name='employmentPlaces'>
            { this.renderFields }
        </FieldArray>
    }
}
