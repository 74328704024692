import React, { Component } from 'react';
import { Input } from 'antd';

import 'antd/lib/input/style/index.css';

import withFieldWrapper from '../../hocs/withFieldWrapper';

class InputComponent extends Component {
    render() {
        const { input: { value }, onChange, maxLength, placeholder, disabled } = this.props;

        return <Input
            value={value}
            onChange={onChange}
            maxLength={maxLength}
            disabled={disabled}
            allowClear
            placeholder={placeholder} />;
    }
}

export default withFieldWrapper(InputComponent);
