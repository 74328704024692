import qs from 'qs';
import { isEmpty } from 'ramda'

export const getUrlParams = (search) => {
    const urlParams = qs.parse(search, { ignoreQueryPrefix: true });

    if (urlParams) {
        return urlParams;
    }
}

export const getFilters = (params) => {
    try {
        return JSON.parse(params.filter);
    } catch {
        return {};
    }
}

export const getFilterUrlString = (filter) => {
    const newParams = qs.stringify({
        filter: isEmpty(filter) ? null : JSON.stringify(filter),
        line: null,
    }, { addQueryPrefix: true, skipNulls: true });

    return newParams;
}

export const getUrlParamsString = (params) => qs.stringify(params, { addQueryPrefix: true, skipNulls: true });

export const filterOption = (input, option) => `${option.props.value || ''}`.toLowerCase().indexOf(`${input || ''}`.toLowerCase()) >= 0;
