import React from 'react';
import cx from 'classnames';
import { Select } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { filterOption } from '../../../utils/filter';
import { is, pathOr } from 'ramda';

const Option = Select.Option;

const SelectComponent = ({ options, valuePath, extraPath,  ...props }) => {
    return (
        <div className={cx('dictionary-select')}>
            <Select
                {...props}
                disabled={props.disabled}
                filterOption={filterOption}
            >
                { (is(Array, options) ? options : []).map(item => (
                    <Option
                        extra={extraPath ? item[extraPath] : null}
                        key={item[valuePath] || item.id}
                        value={valuePath ? item[valuePath] : item.name}
                    >
                        <div className='option-name'>
                            {item.name}
                        </div>
                    </Option>
                ))}
            </Select>
        </div>
    );
}

export default SelectComponent;
