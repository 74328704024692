import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';

import 'antd/lib/checkbox/style/index.css';

import OpdModal from '../fields/OpdModal';
import withFieldWrapper from '../../hocs/withFieldWrapper';

class OpdCheckbox extends Component {
    static propTypes = {
        input: PropTypes.object,
        meta: PropTypes.object
    };

    constructor() {
        super();

        this.state = {
            opened: false
        };
    }

    openModal = (e) => {
        e.preventDefault();
        this.setState({ opened: true });
    }

    closeModal = () => {
        this.setState({ opened: false });
    }

    onChange = e => {
        const { onChange } = this.props.input;
        const { checked } = e.target;

        onChange(checked);
    }

    render() {
        const { input: { value } } = this.props;
        const { opened } = this.state;

        return (
            <div>
                <Checkbox
                    onChange={this.onChange}
                    checked={!!value}
                >
                    <span>Я даю <span className='opd-modal-button underline' onClick={this.openModal} >согласие</span> на обработку персональных данных*</span>
                </Checkbox>
                <OpdModal
                    title='Согласие на обработку персональных данных'
                    visible={opened}
                    onCancel={this.closeModal}

                />
            </div>
        );
    }
}

export default withFieldWrapper(OpdCheckbox);
