import React, { Component } from 'react';
import { DatePicker } from 'antd';
import Switch from 'rc-switch';

import 'rc-switch/assets/index.css';

import withFieldWrapper from '../../hocs/withFieldWrapper';
import { MONTH_YEAR_FORMAT } from '../../../constants/dateFormats';

class RangePicker extends Component {
    static defaultProps = {
        allowNoTo: true,
    }

    changeFrom = (from) => {
        const { input: { value },  onChange } = this.props;
        onChange([from, value[1], value[2]]);
    }

    changeTo = (to) => {
        const { input: { value },  onChange } = this.props;
        onChange([value[0], to, false]);
    }


    disabledDateTo = (current) => {
        const { input: { value } } = this.props;

        return (current && value[0]) && current < value[0];
    }

    disabledDateFrom = (current) => {
        const { input: { value } } = this.props;

        return (current && value[1]) && current > value[1];
    }

    onChangeToPresent = present => {
        const { input: { value }, onChange } = this.props;

        onChange([value[0], null, present]);
    }

    render() {
        const { input: { value }, placeholder, allowNoTo } = this.props;

        return (
            <div className='flex flex-wrap items-center range-picker'>
                <span ref={node => this.containerStartRef = node} className='month-picker-from !mr-2 flex-auto md:flex-none md:min-w-[196px]'>
                    <DatePicker.MonthPicker
                        className='w-full'
                        value={value[0]}
                        onChange={this.changeFrom}
                        format={MONTH_YEAR_FORMAT}
                        allowClear={false}
                        disabledDate={this.disabledDateFrom}
                        placeholder={placeholder[0]}
                        getPopupContainer={() => this.containerStartRef}
                    />
                </span>
                <span ref={node => this.containerEndRef = node} className='month-picker-to flex-auto md:flex-none md:min-w-[196px]'>
                    <DatePicker.MonthPicker
                        className='w-full'
                        value={value[1] || undefined}
                        onChange={this.changeTo}
                        format={MONTH_YEAR_FORMAT}
                        allowClear={false}
                        disabledDate={this.disabledDateTo}
                        disabled={value[2]}
                        placeholder={placeholder[1]}
                        getPopupContainer={() => this.containerEndRef}
                    />
                </span>
                { allowNoTo && (
                    <span className='flex mt-5 md:mt-0 md:inline-flex md:ml-8 range-picker-to-present' >
                        <Switch
                            checked={!!value[2]}
                            onChange={this.onChangeToPresent}
                        />
                        <span className='ml-4 range-picker-to-present-text'>по настоящее время</span>
                    </span>
                )}
            </div>
        );
    }
}

export default withFieldWrapper(RangePicker);
