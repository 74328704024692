import React from 'react';
import { Field } from 'react-final-form';

import FileInput, { FileInputWithWrapper } from './FileInput';
import withLoad from '../../hocs/withLoad';
import { acceptFiles, DEFAULT_MAX_FILE_SIZE } from '../../../constants/files';

const EXCLUDE_FILE_TYPES = ['bmp', 'gif'];

const required = (value) => !value ? 'Документ обязателен' : undefined;
const defaultMaxFileValidation = DEFAULT_MAX_FILE_SIZE / 1048576;
const maxSize = (data, maxDocFileSize = defaultMaxFileValidation) => (data && data.error) ? `Максимальный размер файла ${maxDocFileSize} МБ` : undefined;
const types = (data) => data && EXCLUDE_FILE_TYPES.includes(data.fileType) ? 'Некорректный тип файла' : undefined;

export default function FileUploader({
    name = 'result.file',
    documentType,
    documentName,
    multiple,
    required : isRequired,
    type,
    maxDocFileSize
}) {
    return (
        <div className='file-upload'>
            <Field
                uploadType={type}
                documentType={documentType}
                documentName={documentName}
                name={name}
                component={FileInput}
                placeholder='placeholders.freeResult'
                validate={value => isRequired ? maxSize(value, maxDocFileSize) || types(value) || required(value) : undefined }
                maxDocFileSize={maxDocFileSize}
                multiple={multiple}
            />
        </div>
    );
}

function ResumeInput({ name, options: { maxDocFileSize }, label, formItemLayout }) {
    return (
        <Field
            accept={acceptFiles}
            uploadType={'button'}
            documentType={'resume'}
            documentName={true}
            name={name}
            component={FileInputWithWrapper}
            validate={value => maxSize(value, maxDocFileSize)}
            maxDocFileSize={maxDocFileSize}
            text={'Прикрепить'}
            formItemLayout={formItemLayout}
            label={label}
        />
    )
}

export const ResumeUploader = withLoad(ResumeInput);
