import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { YMaps } from '@pbe/react-yandex-maps';

import 'antd/dist/antd.css';
import './styles/index.css';

import Layout from './components/layout/Layout';
import rollbarInit from './utils/rollbarInit';

import Main from './pages/Main';
import Recruitment from './pages/Recruitment';
import Respond from './pages/Respond';
import Policy from './pages/Policy';
import Form from './pages/Form';
import Success from './pages/Success';
import Vacancy from './pages/Vacancy';

if (import.meta.env.MODE === 'production') {
    rollbarInit({
        token: 'fadf070f930247edbdb5d40eccaf44cd',
        version: import.meta.env.VITE_APP_VERSION,
        environment: window.location.host
    });

    window._version = {
        version: import.meta.env.VITE_APP_VERSION,
    };
}

const ymapsToken = import.meta.env.VITE_YMAPS_TOKEN || undefined;
const ymapsModules = [
  'control.ZoomControl',
  'geocode',
  'geoQuery',
  'geoObject.addon.hint',
  'geoObject.addon.balloon',
  'templateLayoutFactory',
  'layout.Image',
  'util.bounds'
];

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: import.meta.env.MODE === 'production',
        },
    },
});

export default function App() {
    return (
        <YMaps query={ymapsToken ? { apikey: ymapsToken, load: ymapsModules } : { load: ymapsModules }}>
            <QueryClientProvider client={queryClient}>
                <Router>
                    <Layout>
                        <Routes>
                            <Route exact path='/' element={<Main/>} />
                            <Route exact path='/recruitment' element={<Recruitment/>} />
                            <Route exact path='/respond' element={<Respond/>} />
                            <Route exact path='/policy' element={<Policy/>} />
                            <Route exact path='/vacancy/:id' element={<Vacancy />} />
                            <Route exact path='/vacancy/:id/form' element={<Form/>} />
                            <Route exact path='/vacancy/:id/success' element={<Success/>} />
                            <Route exact path='/success' element={<Success/>} />
                            <Route path="*" element={<Navigate to='/' />} />
                        </Routes>
                    </Layout>
                </Router>
            </QueryClientProvider>
        </YMaps>
    );
};
