import * as yup from 'yup';
import moment from 'moment';

import validationLocale, { MAX_DATE_VALIDATION, NOT_FULL_DATE } from '../constants/validationLocale';
import { API_DATE_FORMAT } from '../constants/dateFormats';

yup.setLocale(validationLocale);

yup.addMethod(yup.string, "validateDate", function(message) {
    return yup.mixed().test(`validateDate`, message, function(value) {
        const { path, createError } = this;

        if (!!value) {
            const trimVAlue = value.replace(/\s|\./g, '');

            if (trimVAlue.length === 8) {
                const dateNow = moment();
                const valueDate = moment(value, API_DATE_FORMAT);

                if (dateNow >= valueDate) {
                    return true;
                } else {
                    return createError({ path, message: MAX_DATE_VALIDATION });
                }
            } else {
                return createError({ path, message:  NOT_FULL_DATE });
            }
        }
    });
})

export default yup;
