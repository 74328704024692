import React from 'react';
import { useQuery, useMutation } from 'react-query';
import { path } from 'ramda';

import { ARCHIVE_VACANCY_CODE } from '../../constants/dictionaries';
import { getCurrentUser, getVacancy, postVacancy } from '../../queries';
import { getInernalSubmitError } from '../../utils/form';
import { getUtm } from '../../utils/utm';
import { disableSSO } from '../layout/Layout';
import VacancyForm from '../forms/VacancyForm';

export default function VacancyModal({ id }) {
    const { isError: isErrorUser, isLoading: isLoadingUser, isSuccess: isSuccessUser, data : userData = {} } = useQuery(['currentUser'], getCurrentUser, {
      enabled: !disableSSO,
    });
    const { isError, isLoading, isSuccess, data } = useQuery(['vacancy', id, disableSSO ? 'VacancyMetro' : 'InnerVacancyMetro'], getVacancy, {
        enabled: !!id,
    });

    const { vacancyName, vacancyStateId } = data || {};
    const user = path(['metroData'], userData) || {};

    const postVacancyMutation = useMutation(postVacancy, {
        // onSuccess: () => navigate(`/vacancy/${id}/success`, { replace: true }),
        onError: e => getInernalSubmitError(e),
    });

    return postVacancyMutation.isSuccess ? (
        <div className="container py-56">
            <h2 className="pb-10 pt-6 font-['CA_Metro'] text-[34px] lg:text-[40px] font-black text-center">
                Анкета успешно отправлена
            </h2>
        </div>
    ) : (
      <div className='form'>
          <div className='max-w-[940px]'>
            <h1 className='font-medium max-md:text-[20px] md:max-lg:text-[22px] lg:text-[26px] leading-[140%] pb-[15px] border-b border-blue/40'>Откликнуться на вакансию</h1>
              <div>
                  { (isLoading || (isLoadingUser && !disableSSO)) && (
                    'Загрузка...'
                  )}
                  { isError && (
                    'Неудалось загрузить вакансию'
                  )}
                  { isErrorUser && !disableSSO && (
                    'Неудалось загрузить данные пользователя'
                  )}
                  { (isSuccess && (isSuccessUser || disableSSO)) && (
                    vacancyStateId !== ARCHIVE_VACANCY_CODE ? (
                      <VacancyForm
                        formAction={postVacancyMutation}
                        vacancy={id}
                        utm={getUtm(path(['search'], location) || '')}
                        initialValues={{
                          lastName: user.lastName,
                          firstName: user.firstName,
                          middleName: user.middleName,
                          birthDate: user.birthDate,
                          town: user.town,
                          citizenship: user.citizenship,
                          email: path(['email'], userData),
                          education: {
                              level: path(['education', 'level'], user)
                          },
                        }}
                      />
                    ) : (
                      'Вакансия в архиве'
                    )
                  )}
              </div>
          </div>
      </div>
    );
}
