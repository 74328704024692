import React from "react";
import { useQuery } from 'react-query';
import { path } from 'ramda';

import { getRespondContent } from '../queries';
import SectionBgImg from "../components/layout/SectionBgImg";

export default function Recruitment() {
    const { data : content = {}, isFetching } = useQuery(['respondContent'], getRespondContent);

    const image = path(['Image', 'data', 'attributes', 'url'], content) || '/images/response.jpeg';
    const title = path(['Title'], content) || 'Как откликнуться на вакансию';

    return (
        <>
            <SectionBgImg
                title={title}
                img={image}
                backColor="text-white"
            />
            <div className="container py-[11vh]">
                <div className="grid lg:grid-cols-3 gap-[30px]">
                    <div className="py-[30px] px-10 rounded-20 border border-blue/40">
                        <svg
                            className="size-20 min-w-20 mb-[60px]"
                            xmlns="http://www.w3.org/2000/svg"
                            width="81"
                            height="80"
                            fill="none"
                            viewBox="0 0 81 80"
                            >
                            <rect width="80" height="80" x="0.786" fill="#fff" rx="20"></rect>
                            <path
                                fill="#FFE500"
                                d="M12.786 22c0-5.523 4.477-10 10-10h36c5.523 0 10 4.477 10 10v36c0 5.523-4.477 10-10 10h-36c-5.523 0-10-4.477-10-10V22z"
                            ></path>
                            <path
                                fill="#1A3C7B"
                                d="M18.786 44.84c.227-.473.39-.99.695-1.412.664-.93 1.64-1.38 2.805-1.409a58.61 58.61 0 013.006 0c1.968.049 3.477 1.509 3.484 3.397.014 4.715.014 9.43 0 14.144-.003 1.772-1.282 3.188-3.143 3.358-1.236.113-2.502.11-3.734-.01-1.563-.148-2.555-1.068-3.003-2.521-.026-.084-.073-.161-.11-.245V44.84zM70.722 20.162v39.412c0 1.677-.804 2.854-2.264 3.26-.39.11-.808.145-1.213.155-.969.023-1.941-.013-2.91.01-1.948.045-3.504-1.29-3.497-3.418.036-12.875.016-25.75.016-38.626v-.78c-.435 0-.82.013-1.203 0-.428-.016-.867 0-1.279-.097-1.509-.345-2.063-1.683-1.127-2.889 2.146-2.763 4.321-5.5 6.54-8.206 1.127-1.374 2.979-1.29 4.142.142 1.951 2.402 3.863 4.833 5.791 7.251.171.216.343.432.514.652.475.616.752 1.286.386 2.038-.38.777-1.108 1.054-1.932 1.096-.63.032-1.262.006-1.964.006v-.006zM46.81 44.463c0-4.972.046-9.948-.024-14.92-.03-2.127 1.573-3.667 3.666-3.535.996.061 2 0 2.999.013 1.892.026 3.268 1.35 3.332 3.18.003.11 0 .22 0 .33 0 9.982 0 19.965.003 29.951 0 1.101-.32 2.054-1.21 2.774-.633.513-1.382.733-2.199.74-1.02.006-2.039.006-3.055 0-2.143-.014-3.509-1.325-3.512-3.394-.007-5.046 0-10.093 0-15.14zM32.792 48.452V37.474c0-2.142 1.356-3.468 3.537-3.471 1.039 0 2.081-.007 3.12 0 1.887.013 3.326 1.385 3.33 3.228.01 7.504.01 15.01 0 22.514 0 1.788-1.38 3.163-3.227 3.226-1.172.039-2.344.039-3.517 0-1.89-.06-3.24-1.45-3.246-3.32-.007-3.735 0-7.468 0-11.202l.003.003z"
                            ></path>
                        </svg>
                        <h2 className="font-medium text-[26px] leading-[140%] mb-[15px]">Развитие</h2>
                        <p className="text-[20px] leading-[140%]">Компания METRO уделяет большое внимание профессиональному и&nbsp;карьерному росту и&nbsp;развитию сотрудников</p>
                    </div>
                    <div className="py-[30px] px-10 rounded-20 border border-blue/40">
                        <svg
                            className="size-20 min-w-20 mb-[60px]"
                            xmlns="http://www.w3.org/2000/svg"
                            width="81"
                            height="80"
                            fill="none"
                            viewBox="0 0 81 80"
                            >
                            <rect width="80" height="80" x="0.502" fill="#fff" rx="20"></rect>
                            <path
                                fill="#FFE500"
                                d="M7.622 18.558c0-6.653 5.251-12.046 11.729-12.046h42.223c6.477 0 11.728 5.393 11.728 12.046v43.364c0 6.653-5.251 12.046-11.729 12.046H19.352c-6.478 0-11.729-5.393-11.729-12.046V18.558z"
                            ></path>
                            <path
                                fill="#1A3C7B"
                                d="M40.463 18.558c-.923 0-1.837.187-2.69.55a7.021 7.021 0 00-2.281 1.566 7.239 7.239 0 00-1.524 2.343 7.395 7.395 0 000 5.528 7.24 7.24 0 001.524 2.343 7.021 7.021 0 002.28 1.566c.854.363 1.768.55 2.691.55a6.936 6.936 0 004.971-2.115 7.325 7.325 0 002.06-5.108c0-1.916-.742-3.753-2.06-5.107a6.936 6.936 0 00-4.971-2.116zm15.834 3.608c-1.4 0-2.743.571-3.732 1.588a5.5 5.5 0 00-1.546 3.835 5.5 5.5 0 001.546 3.834 5.207 5.207 0 003.732 1.588c1.4 0 2.742-.571 3.732-1.588a5.5 5.5 0 001.545-3.834 5.5 5.5 0 00-1.545-3.835 5.208 5.208 0 00-3.732-1.588zm-31.668 0c-1.4 0-2.742.571-3.732 1.588a5.5 5.5 0 00-1.545 3.835 5.5 5.5 0 001.545 3.834 5.208 5.208 0 003.732 1.588c1.4 0 2.743-.571 3.732-1.588a5.5 5.5 0 001.546-3.834 5.5 5.5 0 00-1.546-3.835 5.207 5.207 0 00-3.732-1.588zm5.278 18.05a3.665 3.665 0 011.04-2.54 3.471 3.471 0 012.479-1.05H47.5c.933 0 1.828.381 2.488 1.06a3.666 3.666 0 011.03 2.556v10.845c0 1.138-.173 2.268-.513 3.351a10.805 10.805 0 01-4.527 5.889 10.326 10.326 0 01-7.163 1.462 10.492 10.492 0 01-6.373-3.665 11.026 11.026 0 01-2.535-7.037v-10.87zm-3.518.026c0-1.32.341-2.553.943-3.616h-7.98c-.934 0-1.829.381-2.488 1.06a3.666 3.666 0 00-1.031 2.556v9.037c0 1.48.353 2.937 1.028 4.244a8.958 8.958 0 002.848 3.25 8.65 8.65 0 003.999 1.497 8.582 8.582 0 004.215-.607 14.721 14.721 0 01-1.534-6.58V40.242zm28.148 0v10.845c0 2.368-.552 4.602-1.534 6.576a8.582 8.582 0 004.215.607 8.65 8.65 0 004-1.496 8.958 8.958 0 002.847-3.251 9.237 9.237 0 001.028-4.244v-9.038c0-.958-.37-1.878-1.03-2.556a3.471 3.471 0 00-2.489-1.059h-7.98a7.347 7.347 0 01.943 3.616z"
                            ></path>
                        </svg>
                        <h2 className="font-medium text-[26px] leading-[140%] mb-[15px]">Ценность</h2>
                        <p className="text-[20px] leading-[140%]">Мы&nbsp;очень ценим экспертизу и&nbsp;опыт, приобретенные в&nbsp;METRO, поэтому стремимся дать все возможности для твоего дальнейшего развития внутри нашей компании</p>
                    </div>
                    <div className="py-[30px] px-10 rounded-20 border border-blue/40">
                        <svg
                            className="size-20 min-w-20 mb-[60px]"
                            xmlns="http://www.w3.org/2000/svg"
                            width="81"
                            height="80"
                            fill="none"
                            viewBox="0 0 81 80"
                            >
                            <rect width="80" height="80" x="0.217" fill="#fff" rx="20"></rect>
                            <path
                                fill="#1A3C7B"
                                fillRule="evenodd"
                                d="M75.844 58.96L64.757 47.873c.584 3.317-.53 7.21-3.326 10.007-3.127 3.127-7.63 4.167-11.16 3.06-.617 1.18-1.53 2.25-2.64 3.14l11.547 11.547c1.946 1.946 5.276 1.026 5.946-1.647l1.06-4.243a5.178 5.178 0 013.77-3.77l4.244-1.06c2.673-.67 3.593-4 1.646-5.947zM4.59 58.96l11.088-11.087c-.584 3.317.53 7.21 3.326 10.007 3.127 3.127 7.63 4.167 11.16 3.06.617 1.18 1.53 2.25 2.64 3.14L21.257 75.627c-1.946 1.946-5.276 1.026-5.946-1.647l-1.06-4.243a5.178 5.178 0 00-3.77-3.77l-4.244-1.06c-2.673-.67-3.593-4-1.646-5.947z"
                                clipRule="evenodd"
                            ></path>
                            <path
                                fill="#FFE500"
                                fillRule="evenodd"
                                d="M30.164 12.393c1.713-3.276 5.633-5.726 10.053-5.726 4.42 0 8.34 2.45 10.054 5.726 3.526-1.106 8.033-.066 11.16 3.06 3.126 3.127 4.166 7.63 3.06 11.16 3.276 1.714 5.726 5.634 5.726 10.054s-2.45 8.34-5.726 10.053c1.106 3.527.066 8.033-3.06 11.16-3.127 3.127-7.63 4.167-11.16 3.06-1.714 3.277-5.634 5.727-10.054 5.727s-8.34-2.45-10.053-5.727c-3.527 1.107-8.033.067-11.16-3.06-3.127-3.127-4.167-7.63-3.06-11.16-3.277-1.713-5.727-5.633-5.727-10.053 0-4.42 2.45-8.34 5.727-10.054-1.107-3.526-.067-8.033 3.06-11.16 3.127-3.126 7.63-4.166 11.16-3.06z"
                                clipRule="evenodd"
                            ></path>
                            <path
                                fill="#1A3C7B"
                                d="M36.884 50a3.324 3.324 0 01-2.357-.977l-10-10a3.33 3.33 0 010-4.713 3.33 3.33 0 014.714 0l7.643 7.643 14.31-14.31a3.33 3.33 0 014.713 0 3.33 3.33 0 010 4.714L39.241 49.023c-.65.65-1.504.977-2.357.977z"
                            ></path>
                        </svg>
                        <h2 className="font-medium text-[26px] leading-[140%] mb-[15px]">Результат</h2>
                        <p className="text-[20px] leading-[140%]">Любой сотрудник, показывающий хорошие результаты работы, может откликнуться на&nbsp;любую вакансию ЦО, ТЦ, МОЦО или МГЛ (требования к&nbsp;опыту работы и&nbsp;другим навыкам указываются в&nbsp;объявлении на&nbsp;вакансию)</p>
                    </div>
                </div>
            </div>
            <div className="bg-blue rounded-20 py-[140px] relative overflow-hidden mb-[11vh]">
                <svg
                    className="absolute inset-y-0 object-cover object-center h-full max-lg:hidden w-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    width="2381"
                    height="692"
                    fill="none"
                    viewBox="0 0 2381 692"
                    >
                    <g clipPath="url(#clip0_6115_18813)">
                        <path
                        fill="#1E448B"
                        d="M1503.73 905.934H932.319L1433.63.065h571.41l-501.31 905.869z"
                        ></path>
                        <path
                        fill="#163570"
                        d="M597.755.001c116.46 0 221.757 49.568 267.898 126.151l196.987 326.816c86.73 142.684 168.12 277.992 183.66 308.502 65.62 128.708 222.35 144.464 222.35 144.464H870.895c-116.46 0-221.757-49.567-267.898-126.151L406.005 452.968c-86.723-142.685-168.114-277.993-183.66-308.503C156.772 15.758.001.001.001.001h597.754zM1993.38.065c44.96.55 106.33 9.35 138.6 84.834l264.46 706.046s8.64 25.658 50.71 59.08c42.12 33.358 104.8 55.909 104.8 55.909h-266.72c-65.44 0-156.55-25.948-178.78-84.834l-264.47-706.046s-18.34-45.232-33.21-67.913C1785.31 11.389 1726.65.033 1726.65.033h266.73v.032z"
                        ></path>
                    </g>
                    <defs>
                        <clipPath id="clip0_6115_18813">
                        <path
                            fill="#fff"
                            d="M0 0H692V2381H0z"
                            transform="rotate(-90 346 346)"
                        ></path>
                        </clipPath>
                    </defs>
                </svg>
                <div className="container relative z-[2]">
                    <h2 className="font-display mb-[60px] font-medium text-white text-[46px] leading-[100%] tracking-[0.46px]">Если ты&nbsp;чувствуешь, что готов развиваться дальше, то&nbsp;тебе необходимо</h2>
                    <div className="grid lg:grid-cols-3 gap-[60px]">
                        <div>
                            <div className="flex gap-2.5 items-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="48"
                                    fill="none"
                                    viewBox="0 0 22 48"
                                    >
                                    <path
                                        fill="#FFEC43"
                                        d="M21.777 23.458l-6.784-3.691a.44.44 0 00-.592.17l-2.09 3.762L.83 1.4a.426.426 0 01.192-.58L8.548.044a.438.438 0 01.585.2l12.75 23.311a.36.36 0 00-.106-.098z"
                                    ></path>
                                    <path
                                        fill="#FFE500"
                                        d="M21.944 24.044l-.22.377-6.796 3.425a.438.438 0 01-.588-.19L12.305 23.7l2.09-3.764a.441.441 0 01.592-.17l6.783 3.692a.359.359 0 01.117.094l.095.171a.424.424 0 01-.038.321z"
                                    ></path>
                                    <path
                                        fill="#FFEC43"
                                        d="M14.929 27.846l6.796-3.425L8.032 47.777a.431.431 0 01-.261.206.44.44 0 01-.333-.037L.226 46.352a.436.436 0 01-.209-.258.425.425 0 01.038-.328l12.251-22.058 2.035 3.956a.429.429 0 00.256.212.44.44 0 00.332-.03z"
                                    ></path>
                                </svg>
                                <span className="inline-flex text-[64px] text-white font-medium leading-[140%] relative top-1.5">1</span>
                            </div>
                            <p className="text-white text-[20px] leading-[140%] mt-5">Перейти на&nbsp;внутренний карьерный сайт METRO и&nbsp;выбрать понравившуюся вакансию.</p>
                        </div>
                        <div>
                            <div className="flex gap-2.5 items-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="48"
                                    fill="none"
                                    viewBox="0 0 22 48"
                                    >
                                    <path
                                        fill="#FFEC43"
                                        d="M21.777 23.458l-6.784-3.691a.44.44 0 00-.592.17l-2.09 3.762L.83 1.4a.426.426 0 01.192-.58L8.548.044a.438.438 0 01.585.2l12.75 23.311a.36.36 0 00-.106-.098z"
                                    ></path>
                                    <path
                                        fill="#FFE500"
                                        d="M21.944 24.044l-.22.377-6.796 3.425a.438.438 0 01-.588-.19L12.305 23.7l2.09-3.764a.441.441 0 01.592-.17l6.783 3.692a.359.359 0 01.117.094l.095.171a.424.424 0 01-.038.321z"
                                    ></path>
                                    <path
                                        fill="#FFEC43"
                                        d="M14.929 27.846l6.796-3.425L8.032 47.777a.431.431 0 01-.261.206.44.44 0 01-.333-.037L.226 46.352a.436.436 0 01-.209-.258.425.425 0 01.038-.328l12.251-22.058 2.035 3.956a.429.429 0 00.256.212.44.44 0 00.332-.03z"
                                    ></path>
                                </svg>
                                <span className="inline-flex text-[64px] text-white font-medium leading-[140%] relative top-1.5">2</span>
                            </div>
                            <p className="text-white text-[20px] leading-[140%] mt-5">Оповестить о&nbsp;своих планах руководителя, чтобы он&nbsp;был в&nbsp;курсе (руководитель не&nbsp;может воспрепятствовать твоему решению, но&nbsp;мы&nbsp;&mdash; одна команда, а&nbsp;поэтому о&nbsp;таких решениях важно уведомлять заблаговременно), и&nbsp;получить письменное подтверждение.</p>
                        </div>
                        <div>
                            <div className="flex gap-2.5 items-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="48"
                                    fill="none"
                                    viewBox="0 0 22 48"
                                    >
                                    <path
                                        fill="#FFEC43"
                                        d="M21.777 23.458l-6.784-3.691a.44.44 0 00-.592.17l-2.09 3.762L.83 1.4a.426.426 0 01.192-.58L8.548.044a.438.438 0 01.585.2l12.75 23.311a.36.36 0 00-.106-.098z"
                                    ></path>
                                    <path
                                        fill="#FFE500"
                                        d="M21.944 24.044l-.22.377-6.796 3.425a.438.438 0 01-.588-.19L12.305 23.7l2.09-3.764a.441.441 0 01.592-.17l6.783 3.692a.359.359 0 01.117.094l.095.171a.424.424 0 01-.038.321z"
                                    ></path>
                                    <path
                                        fill="#FFEC43"
                                        d="M14.929 27.846l6.796-3.425L8.032 47.777a.431.431 0 01-.261.206.44.44 0 01-.333-.037L.226 46.352a.436.436 0 01-.209-.258.425.425 0 01.038-.328l12.251-22.058 2.035 3.956a.429.429 0 00.256.212.44.44 0 00.332-.03z"
                                    ></path>
                                </svg>
                                <span className="inline-flex text-[64px] text-white font-medium leading-[140%] relative top-1.5">3</span>
                            </div>
                            <p className="text-white text-[20px] leading-[140%] mt-5">Любой сотрудник, показывающий хорошие результаты работы, может откликнуться на&nbsp;любую вакансию ЦО, ТЦ, МОЦО или МГЛ (требования к&nbsp;опыту работы и&nbsp;другим навыкам указываются в&nbsp;объявлении на&nbsp;вакансию).</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
