import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';

import { formItemLayout } from '../../../constants/forms';
import Input from '../formComponents/Input';
import DatePicker from '../formComponents/DatePicker';
import { LoadSelect } from '../formComponents/Select';

export default class PersonalInfoFields extends Component {
    render() {
        return <Fragment>
            <div className='md:flex md:mx-[-10px]'>
                <div className='md:px-[10px] w-full'>
                    <Field
                        name='lastName'
                        component={Input}
                        label='Фамилия'
                        required
                        formItemLayout={formItemLayout} />
                </div>
                <div className='md:px-[10px] w-full'>
                    <Field
                        name='firstName'
                        component={Input}
                        label='Имя'
                        required
                        formItemLayout={formItemLayout} />
                </div>
            </div>
            <Field
                name='middleName'
                component={Input}
                label='Отчество'
                formItemLayout={formItemLayout} />
            <div className='md:flex md:mx-[-10px]'>
                <div className='md:px-[10px] w-full'>
                <Field
                    name='birthDate'
                    component={DatePicker}
                    label='Дата рождения'
                    required
                    formItemLayout={formItemLayout}
                    disableFutureDates={true} />
                </div>
                <div className='md:px-[10px] w-full'>
                <Field
                    name='town'
                    component={LoadSelect}
                    label='Город'
                    required
                    formItemLayout={formItemLayout}
                    loadType='TownList'
                    isSearchable={true} />
                </div>
            </div>
            <Field
                name='citizenship'
                component={LoadSelect}
                label='Гражданство'
                required
                formItemLayout={formItemLayout}
                loadType='CountryList'
                isSearchable={true} />
        </Fragment>;
    }
}
