import React, { useState } from 'react';
import { Table } from 'antd';
import { append, remove, path } from 'ramda';
import { useQuery } from 'react-query';
import moment from 'moment';

import { getPoliticsContent } from '../queries';
import BackButton from "../components/layout/BackButton";

const data = [
    {
        key: 1,
        name: 'MCC',
        changed: '30/09/2022',
        size: '5 файлов',
        folder: true,
        children: [
            {
                key: 11,
                name: 'Recruitment Policy',
                changed: '30/09/2022',
                size: '298 Кб',
                src: '/mcc/Recruitment Policy_2022.pdf',
            },
            {
                key: 12,
                name: 'Attachment 1',
                changed: '30/09/2022',
                size: '479 Кб',
                src: '/mcc/Attachment 1 Recruitment request.xlsx',
            },
            {
                key: 13,
                name: 'Attachment 2',
                changed: '30/09/2022',
                size: '174 Кб',
                src: '/mcc/Attachment 2 HQ matrix 2022.pdf',
            },
            {
                key: 14,
                name: 'Attachment 3',
                changed: '30/09/2022',
                size: '211 Кб',
                src: '/mcc/Attachment 3 Stores matrix 2022.pdf',
            },
            {
                key: 15,
                name: 'Attachment 4',
                changed: '30/09/2022',
                size: '156 Кб',
                src: '/mcc/Attachment 4 Grades-levels matrix 2022.pdf',
            },
        ],
    },
    {
        key: 2,
        name: 'MGL',
        changed: '30/09/2022',
        size: '1 файл',
        folder: true,
        children: [{
            key: 21,
            name: 'Положение по подбору персонала',
            changed: '30/09/2022',
            size: '90 Кб',
            src: '/mgl/1_Положение  по подбору персонала_с 01.01.2021.pdf',
        }],
    },
];

const columns = [
    {
        title: 'Имя',
        dataIndex: 'name',
        key: 'name',
        render: (name, { folder, src }) => folder ? (
            <div className='cursor-pointer'>{name}</div>
        ) : (
            <a href={src} download onClick={event => event.stopPropagation()} className='cursor-pointer'>
                {name}
            </a>
        )
    },
    {
        title: 'Изменено',
        dataIndex: 'changed',
        key: 'changed',
        width: '150px',
    },
    {
        title: 'Размер',
        dataIndex: 'size',
        width: '150px',
        key: 'size',
    },
    {
        title: '',
        dataIndex: 'folder',
        key: 'download',
        width: '56px',
        render: (folder, item) => folder ? null : item.src && (
            <a href={item.src} download onClick={event => event.stopPropagation()}>
                <svg
                    className='min-w-6 mr-[30px] opacity-40 hover:opacity-100'
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                    >
                    <path
                        fill="#1A3C7B"
                        fillRule="evenodd"
                        d="M12 2a1 1 0 011 1v9.586l3.293-3.293a1 1 0 111.414 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 011.414-1.414L11 12.586V3a1 1 0 011-1zM4 15a1 1 0 011 1v2a1 1 0 001 1h12a1 1 0 001-1v-2a1 1 0 012 0v2a3 3 0 01-3 3H6a3 3 0 01-3-3v-2a1 1 0 011-1z"
                        clipRule="evenodd"
                    ></path>
                </svg>
            </a>
        ),
    }
];

export default function Policy() {
    const { data : content = {}, isFetching } = useQuery(['respondContent'], getPoliticsContent);

    const title = path(['Title'], content) || 'Политика по  подбору персонала MCC RU';

    const [expanded, setExpanded] = useState([]);

    const onExpand = key =>  {
        const expandedIndex = expanded.indexOf(key);
        if (expandedIndex === -1) {
            setExpanded(append(key, expanded));
        } else {
            setExpanded(remove(expandedIndex, 1, expanded));
        }
    };

    const dataSource = path(['FileFolder'], content) ? (
        path(['FileFolder'], content).map((folder) => ({
            key: path(['id'], folder),
            name: path(['Title'], folder),
            changed: moment(path(['Files', 'data', path(['Files', 'data', 'length'], folder) - 1, 'attributes', 'updatedAt'], folder)).format('DD/MM/YYYY'),
            size: `${path(['Files', 'data', 'length'], folder) || 0} файлов`,
            folder: true,
            childrens: path(['Files', 'data'], folder).map((file) => ({
                key: `${path(['id'], folder)}${path(['id'], file)}`,
                name: path(['attributes', 'caption'], file),
                changed: moment(path(['attributes', 'updatedAt'], file)).format('DD/MM/YYYY'),
                size: `${path(['attributes', 'size'], file)} Кб`,
                src: path(['attributes', 'url'], file),
            })),
        }))
    ) : data;

    const expandedRowRender = (record) => {
        return (
            <Table
                columns={columns}
                dataSource={record.childrens}
                pagination={false}
            />
        )
    }

    return isFetching ? null : (
        <>
            <div className="container pt-10 pb-[140px]">
                <BackButton btnBackClass="text-blue" />
                <h1 className="font-medium font-display text-[64px] leading-[100%] my-[60px]">{title}</h1>
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    expandedRowKeys={expanded}
                    onExpand={(state, record) => {
                        onExpand(record.key);
                    }}
                    expandedRowRender={expandedRowRender}
                    onRow={(record) => ({
                        onClick: () => {
                            if (record.folder) {
                                onExpand(record.key);
                            }
                        }
                    })}
                />
            </div>
        </>
    )
}
