import React, { Fragment } from 'react';
import { Row, Col, Radio, Checkbox } from 'antd';
import { omit, assoc, dissoc, isEmpty, path } from 'ramda';

import SelectWithDictionary from '../filters/filterComponent/SelectWithDictionary';
import Select from '../filters/filterComponent/Select';
import { vacancyTypes, internalEmploymentType, internalWorkExperience, internalWorkSchedule } from '../../constants/filters';
import { filterOption } from '../../utils/filter';
import { useQuery } from 'react-query';
import { getStoreTowns, getMglTowns, getHQMetroList, getProfessions, getStores, getMgls } from '../../queries';
import withLoad from '../hocs/withLoad';

const InternalFilter = props => {
    const { filters } = props;
    const type = filters.type || 'office';
    const isOffice = type === 'office';
    const isStore = type === 'store';
    const isStock = type === 'mgl';

    const { data: industries } = useQuery(['dictionaryVacancyIndustry'], getProfessions);
    const officeDictionary = useQuery(['dictionaryMetroHQAddressList'], getHQMetroList, {
        enabled: isOffice,
    });
    const storeTownDictionary = useQuery(['storeTownDictionary'], getStoreTowns, {
        enabled: isStore,
    });
    const storesDictionary = useQuery(['storesDictionary', filters.storeCity], getStores, {
        enabled: isStore,
    });
    const mglTownDictionary = useQuery(['mglTownDictionary'], getMglTowns, {
        enabled: isStock,
    });
    const mglsDictionary = useQuery(['mglsDictionary', filters.storeCity], getMgls, {
        enabled: isStock,
    });

    const changeFilter = name => value => {
        const { filters, setFilter } = props;
        setFilter(value ? { ...filters, [name]: value } : omit([name], filters));
    };

    const changeType = event => {
        props.setFilter({
            ...omit(['storeCity', 'city', 'store', 'hq'], props.filter),
            type: event.target.value
        });
    }

    const changeCity = (value, item) => {
        props.setFilter(value ? { ...props.filters, storeCity: value, city: Number(item.key) } : omit(['city', 'storeCity'], props.filters));
    }

    return <Fragment>
            <div className="flex flex-col gap-5 p-5 rounded-20 border border-blue/40 min-w-[320px] max-lg:w-full lg:w-[487px] self-start shadow-[0_0_15px_0_rgba(0,0,0,0.07)]">
                <Radio.Group className="!flex flex-wrap gap-2.5 mb-[30px]" buttonStyle="solid" onChange={changeType} value={type}>
                    { vacancyTypes.map((office) => (
                        <Radio.Button key={office.id} className='vertical-radio text-center cursor-pointer py-3 px-5 border border-blue/40 rounded-lg text-blue text-sm font-medium uppercase leading-[16.80px] relative top-0.5' value={office.id}>{office.name}</Radio.Button>
                    ))}
                </Radio.Group>
                { isOffice && (
                    <div>
                        <SelectWithDictionary
                            action={officeDictionary}
                            value={filters.office}
                            size="large"
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Адрес офиса"
                            optionFilterProp="children"
                            onChange={changeFilter('hq')}
                            allowClear={true}
                            valuePath='id'
                            filterOption={filterOption}
                            extraPath='place'
                        />
                    </div>
                )}
                { (isStore || isStock) && (
                    <Fragment>
                        <div>
                            <SelectWithDictionary
                                action={isStock ? mglTownDictionary : storeTownDictionary}
                                value={filters.storeCity}
                                size="large"
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Город"
                                optionFilterProp="children"
                                onChange={changeCity}
                                allowClear={true}
                                filterOption={filterOption}
                            />
                        </div>
                        <div>
                            <SelectWithDictionary
                                action={isStock ? mglsDictionary : storesDictionary}
                                value={filters.store}
                                size="large"
                                showSearch
                                style={{ width: '100%' }}
                                placeholder={isStock ? 'Адрес склада' : "Адрес торгового центра"}
                                optionFilterProp="children"
                                onChange={changeFilter('store')}
                                allowClear={true}
                                valuePath='id'
                                filterOption={filterOption}
                                extraPath='place'
                            />
                        </div>
                    </Fragment>
                )}
                <div>
                    <Select
                        options={internalWorkExperience}
                        value={filters.experience}
                        size="large"
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Требуемый опыт работы"
                        optionFilterProp="children"
                        onChange={changeFilter('experience')}
                        allowClear={true}
                        filterOption={filterOption}
                        valuePath='value'
                    />
                </div>
                <div>
                    <Select
                        options={internalEmploymentType}
                        value={filters.work}
                        size="large"
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Выбрать занятость"
                        optionFilterProp="children"
                        onChange={changeFilter('work')}
                        allowClear={true}
                        filterOption={filterOption}
                        valuePath='value'
                    />
                </div>
                <div>
                    <Select
                        options={internalWorkSchedule}
                        value={filters.schedule}
                        size="large"
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Выбрать график"
                        optionFilterProp="children"
                        onChange={changeFilter('schedule')}
                        allowClear={true}
                        filterOption={filterOption}
                        valuePath='value'
                    />
                </div>
                <div>
                    <Select
                        options={industries}
                        valuePath='id'
                        value={filters.profession}
                        size="large"
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Профессиональная область"
                        optionFilterProp="children"
                        onChange={changeFilter('profession')}
                        allowClear={true}
                        filterOption={filterOption}
                    />
                </div>
            </div>
    </Fragment>
}

export default withLoad(InternalFilter);
