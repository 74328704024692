import axios from 'axios';
import { omit } from 'ramda';

axios.defaults.timeout = 5 * 60 * 1000;

export function addDefaultHeader(header, value) {
    axios.defaults.headers.common[header] = value;
}

export function removeDefaultHeader(header) {
    axios.defaults.headers.common[header] = omit([header], axios.defaults.headers.common);
}

export function handleTokenChange(token) {
    if (token) {
        localStorage.setItem('token', token);
        addDefaultHeader('X-Authorization', token);
    } else {
        localStorage.removeItem('token');
        removeDefaultHeader('X-Authorization');
    }
}

export function getToken() {
    return localStorage.getItem('token');
}
