import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { head, path } from 'ramda';
import { Form } from 'react-final-form';
import { equals, assocPath } from 'ramda';
import arrayMutators from 'final-form-arrays';
import createDecorator from 'final-form-focus';

import { RequiredFieldsContext } from '../../constants/context';
import { getRequiredFields } from '../../utils/getRequiredFields';

const getErrorInputs = () => document.querySelectorAll('.error input');
const focusFirstErrorInput = (errorInputs) => ({
    focus: () => {
        const element = head(errorInputs);
        if (element && element.focus) {
            element.focus();
        }
    },
})

const focusOnError = createDecorator(
    getErrorInputs,
    focusFirstErrorInput,
);

const withFormWrapper = (WrappedComponent, formOptions = {}) =>
    class FormWrapper extends Component {
        static propTypes = {
            formAction: PropTypes.object,
            mapBeforeSubmit: PropTypes.func
        };

        constructor(props) {
            super(props);

            this.state = {
                initialValues: this.getInitialValues(props),
            };
        }

        componentDidUpdate(prev) {
            const initialValues = this.getInitialValues();

            if (!equals(initialValues, this.getInitialValues(prev))) {
                this.setState({ initialValues });
            }
        }

        getInitialValues = (props = this.props) => {
            return formOptions.mapPropsToValues ? formOptions.mapPropsToValues(props) : {};
        }

        onSubmit = values => {
            const { formAction } = this.props;
            const { onSubmit } = formOptions;

            if (onSubmit) {
                onSubmit(values, this.props);
            } else {
                formAction && formAction.mutate && formAction.mutate(
                    formOptions.mapBeforeSubmit ? formOptions.mapBeforeSubmit(values, this.props) : values
                );
            }
        }

        handleSubmit = (e, handleSubmit) => {
            handleSubmit(e);
        }

        validate = values => {
            let schema = formOptions.validationSchema;

            if (!schema) {
                return {};
            }

            if (typeof schema === 'function') {
                schema = schema(this.props, values);
            }

            try {
                schema.validateSync(values, { abortEarly: false });
            } catch (e) {
                return e.inner.reduce((errors, error) => assocPath(error.path.split('.'), error.message, errors), {});
            }
        }

        render() {
            return <RequiredFieldsContext.Provider value={getRequiredFields(formOptions.validationSchema)}>
                <Form
                    subscription={{ submitting: true, invalid: true, submitFailed: true, error: true }}
                    onSubmit={this.onSubmit}
                    decorators={[focusOnError]}
                    initialValues={this.state.initialValues}
                    validate={this.validate}
                    mutators={arrayMutators}
                    render={props =>
                        <form onSubmit={props.handleSubmit} noValidate>
                            <WrappedComponent
                                {...this.props}
                                {...props}
                                pending={path(['isLoading'], this.props.formAction)}
                            />
                        </form>
                    }
                />
            </RequiredFieldsContext.Provider>;
        }
    }

export default withFormWrapper;
