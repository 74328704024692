import React, { Component } from 'react';
import { Field, FormSpy } from 'react-final-form';
import { Collapse } from 'antd';

import 'antd/lib/collapse/style/index.css';

import { formItemLayout } from '../../constants/forms';
import { PHONE_VALIDATION } from '../../constants/validationLocale';
import { WANT_TEMP, WORK_SMENA, DRIVING } from '../../constants/dictionaries';
import yup from '../../utils/yup';
import { mapBeforeSubmit, getBirthDate } from '../../utils/submission';
import withFormWrapper from '../hocs/withFormWrapper';
import Input from './formComponents/Input';
import Radio from './formComponents/Radio';
import { LoadSelect } from './formComponents/Select';
import OpdCheckbox from './formComponents/OpdCheckbox';
import { ResumeUploader } from './formComponents/FileUploader';
import WorkExperienceFields from './fields/WorkExperienceFields';
import ContactFields from './fields/ContactFields';
import PersonalInfoFields from './fields/PersonaInfoFields';

class VacancyForm extends Component {
    getCollapseError = (fieldName, errors, text = 'Этот блок обязателен') => (
        this.props.submitFailed && errors[fieldName] && (
            <div className='collapse-error'>
                { text }
            </div>
        )
    )

    render() {
        const { handleSubmit, pending } = this.props;

        return (
            <div>
                <div>
                        <h2 className='title-h2'>Личные данные</h2>
                        <PersonalInfoFields />
                        <Field
                            name='permissionsToWork'
                            component={Input}
                            label='Разрешение на работу'
                            formItemLayout={formItemLayout} />
                        <h2 className='title-h2'>Образование</h2>
                            <Field
                                name='education.level'
                                component={LoadSelect}
                                label='Образование'
                                className='required'
                                formItemLayout={formItemLayout}
                                loadType='MetroPplEduLevel'
                                required />
                            <Field
                                name='driverLicense'
                                component={Radio}
                                label='Водительские права категории B'
                                formItemLayout={formItemLayout}
                                options={DRIVING} />
                        <h2 className='title-h2'>Контактные данные</h2>
                        <ContactFields />
                        <FormSpy
                            subscription={{ errors: true }}
                        >
                            { ({ errors }) => (
                                <Collapse ghost expandIconPosition='end' bordered={false} destroyInactivePanel>
                                    <Collapse.Panel
                                        header="Опыт работы"
                                        key="1"
                                        extra={this.getCollapseError('employmentPlaces', errors)}
                                        forceRender={true}
                                    >
                                        <div>
                                            <div className='alert mb-10'>
                                                <span className="icon">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.9043 8.52067 13 8.28333 13 8C13 7.71667 12.9043 7.479 12.713 7.287C12.521 7.09567 12.2833 7 12 7C11.7167 7 11.4793 7.09567 11.288 7.287C11.096 7.479 11 7.71667 11 8C11 8.28333 11.096 8.52067 11.288 8.712C11.4793 8.904 11.7167 9 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6873 5.825 19.975 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26267 14.6833 2 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31267 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.31233 8.1 2.787C9.31667 2.26233 10.6167 2 12 2C13.3833 2 14.6833 2.26233 15.9 2.787C17.1167 3.31233 18.175 4.025 19.075 4.925C19.975 5.825 20.6873 6.88333 21.212 8.1C21.7373 9.31667 22 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6873 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6873 15.9 21.212C14.6833 21.7373 13.3833 22 12 22ZM12 20C14.2167 20 16.1043 19.221 17.663 17.663C19.221 16.1043 20 14.2167 20 12C20 9.78333 19.221 7.89567 17.663 6.337C16.1043 4.779 14.2167 4 12 4C9.78333 4 7.896 4.779 6.338 6.337C4.77933 7.89567 4 9.78333 4 12C4 14.2167 4.77933 16.1043 6.338 17.663C7.896 19.221 9.78333 20 12 20Z" fill="#003D7C"/>
                                                    </svg>
                                                </span>
                                                <p>{'При прикреплении резюме, раздел "Опыт работы" заполнять не нужно'}</p>
                                            </div>
                                            <WorkExperienceFields />
                                        </div>
                                    </Collapse.Panel>
                                    <Collapse.Panel header="Дополнительная информация " key="2">
                                        <div>
                                            <Field
                                                name='wishes.wantTemp'
                                                component={Radio}
                                                label='Тип занятости'
                                                formItemLayout={formItemLayout}
                                                options={WANT_TEMP} />
                                                <div className='form-item-width'>
                                                    <Field
                                                        name='wishes.workSmena'
                                                        component={Radio}
                                                        label='Желаемый график работы'
                                                        formItemLayout={formItemLayout}
                                                        options={WORK_SMENA} />
                                                </div>
                                        </div>
                                    </Collapse.Panel>
                                </Collapse>
                            )}
                        </FormSpy>
                        <div className='form-item-width'>
                            <ResumeUploader
                                name='attachments.0'
                                loadType='limits'
                            />
                        </div>
                        <div className='form-item-width'>
                            <Field
                                name='SoglasieOPD'
                                component={OpdCheckbox}
                            />
                        </div>
                </div>
                <div>
                    <button className='btn btn-md btn-primary w-full normal-case mb-[30px]' onClick={handleSubmit} disabled={pending}>
                        Отправить анкету
                    </button>
                </div>
            </div>
        );
    }
}

const validationSchema = yup.object().shape({
    lastName: yup.string().required(),
    firstName: yup.string().required(),
    birthDate: yup.string().validateDate().required(),
    town: yup.number().required(),
    citizenship: yup.number().required(),
    education: yup.object().shape({
        level: yup.number().required()
    }),
    email: yup.string().email().required(),
    phone: yup.string().required().min(11, PHONE_VALIDATION).max(13, PHONE_VALIDATION),
    SoglasieOPD: yup.bool().oneOf([true]).required(),
});

export default withFormWrapper(VacancyForm, {
    validationSchema,
    mapPropsToValues: ({ initialValues = {}, vacancy, utm }) => ({
        ...initialValues,
        birthDate: initialValues.birthDate,
        vackey: Number(vacancy) || undefined,
        employmentPlaces: [{}],
        utm
    }),
    mapBeforeSubmit
});
