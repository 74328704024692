import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { includes } from 'ramda';

import { RequiredFieldsContext } from '../../constants/context';

const withFieldWrapper = WrappedComponent =>
    class FieldWrapper extends Component {
        static propTypes = {
            input: PropTypes.object,
            onChange: PropTypes.func,
            label: PropTypes.string,
            meta: PropTypes.object,
            hide: PropTypes.bool,
            formItemLayout: PropTypes.object,
            required: PropTypes.bool,
            className: PropTypes.string,
        };

        static defaultProps = {
            hide: false
        };

        componentDidUpdate(prev) {
            if (prev.hide !== this.props.hide) {
                this.onChange(this.props.hide ? undefined : null);
            }
        }

        onChange = (value, option) => {
            const { onChange } = this.props;

            this.props.input.onChange(value);
            onChange && onChange(value, option);
        }

        render() {
            const { className, label, meta: { error, submitFailed }, hide, extra, formItemLayout, required, input: { name }} = this.props;
            const showError = error && submitFailed;

            return hide ? null : <RequiredFieldsContext.Consumer>
                { fields => (
                    <div className={cx('form-item', { error: showError ? error : null, required: includes(name, fields) || required })}>
                        <label htmlFor={name}>
                            {label}
                            <small>{extra}</small>
                        </label>
                        <div>
                            <WrappedComponent
                                className={className}
                                {...this.props}
                                onChange={this.onChange}
                            />
                        </div>
                        <b>
                            {showError ? error : null}
                        </b>
                    </div>
                )}
            </RequiredFieldsContext.Consumer>;
        }
    };

export default withFieldWrapper;
