import Rollbar from 'rollbar';
import { any, mergeLeft, always } from 'ramda';

const defaults = {
    getPayload: always(null),
    domains: ['.*?']
};

const ignoredMessages = [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications',
    'Script error',
];

export default function (options) {
    options = mergeLeft(defaults, options);

    window._rollbarConfig = {
        enabled: true,
        accessToken: options.token,
        captureUncaught: true,
        payload: {
            environment: options.environment,
            client: {
                javascript: {
                    source_map_enabled: true,
                    code_version: options.version,
                    guest_uncaught_frames: true
                }
            }
        },
        ignoredMessages,
        checkIgnore: function(isUncaught, args) {
            if (isUncaught && any(message => args[0] && args[0].indexOf(message) === 0, ignoredMessages)) {
                return true;
            }

            return false;
        },
    };

    Rollbar.init(window._rollbarConfig);
}

export const pushRollbarError = (error, info) => {
    if (window._rollbarConfig) {
        Rollbar.configure({
            payload: {
                info
            }
        });

        Rollbar.error(error);
    }
}
