const validationLocale = {
    mixed: {
        required: 'Это поле обязательно',
        oneOf: 'Это поле обязательно',
    },
    string: {
        email: 'Неверный формат email'
    }
};

export default validationLocale;
export const PHONE_VALIDATION = 'Номер телефона должен содержать от 11-13 цифр';
export const MAX_DATE_VALIDATION = 'Некорректная дата';
export const NOT_FULL_DATE = 'Дата введена не полностью';
