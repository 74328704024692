import React from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { path } from 'ramda';

import { ARCHIVE_VACANCY_CODE } from '../constants/dictionaries';
import { getCurrentUser, getVacancy, postVacancy } from '../queries';
import { getInernalSubmitError } from '../utils/form';
import { getUtm } from '../utils/utm';
import { disableSSO } from '../components/layout/Layout';
import BackButton from '../components/layout/BackButton';
import VacancyForm from '../components/forms/VacancyForm';

export default function Form() {
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();

    const { isError: isErrorUser, isLoading: isLoadingUser, isSuccess: isSuccessUser, data : userData = {} } = useQuery(['currentUser'], getCurrentUser, {
      enabled: !disableSSO,
    });
    const { isError, isLoading, isSuccess, data } = useQuery(['vacancy', id, disableSSO ? 'VacancyMetro' : 'InnerVacancyMetro'], getVacancy, {
        enabled: !!id,
    });

    const { vacancyName, vacancyStateId } = data || {};
    const user = path(['metroData'], userData) || {};

    const postVacancyMutation = useMutation(postVacancy, {
        onSuccess: () => navigate(`/vacancy/${id}/success`, { replace: true }),
        onError: e => getInernalSubmitError(e),
    });

    return (
      <div className='form container pt-6 lg:pt-12 pb-10 lg:pb-24'>
          <div className='max-w-[940px]'>
              <BackButton />
              <h1 className='mt-6 mb-12 font-["CA_Metro"] font-black text-[40px] leading-10 text-dark'>Откликнуться на вакансию</h1>
              <div className='min-h-screen'>
                  { (isLoading || (isLoadingUser && !disableSSO)) && (
                    'Загрузка...'
                  )}
                  { isError && (
                    'Неудалось загрузить вакансию'
                  )}
                  { isErrorUser && !disableSSO && (
                    'Неудалось загрузить данные пользователя'
                  )}
                  { (isSuccess && (isSuccessUser || disableSSO)) && (
                    vacancyStateId !== ARCHIVE_VACANCY_CODE ? (
                      <VacancyForm
                        formAction={postVacancyMutation}
                        vacancy={id}
                        utm={getUtm(path(['search'], location) || '')}
                        initialValues={{
                          lastName: user.lastName,
                          firstName: user.firstName,
                          middleName: user.middleName,
                          birthDate: user.birthDate,
                          town: user.town,
                          citizenship: user.citizenship,
                          email: path(['email'], userData),
                          education: {
                              level: path(['education', 'level'], user)
                          },
                        }}
                      />
                    ) : (
                      'Вакансия в архиве'
                    )
                  )}
              </div>
          </div>
      </div>
    );
}
