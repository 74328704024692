import React from "react";
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from "react-router-dom";
import { path } from 'ramda';

import { getMainContent } from '../queries';
import { disableSSO } from '../components/layout/Layout';
import InternalFilter from "../components/filters/InternalFilter";
import VacanciesList from "../components/vacancies/VacanciesList";
import { getFilters, getFilterUrlString, getUrlParams } from "../utils/filter";
import SectionBgImg from "../components/layout/SectionBgImg";

export default function Main() {
    const { data = {}, isFetching } = useQuery(['mainContent'], getMainContent);

    const navigate = useNavigate();
    const location = useLocation();
    const params = getUrlParams(location.search);
    const filters = getFilters(params);

    const setFilter = filter => {
        navigate(`${location.pathname}${getFilterUrlString(filter)}`);
    };

    const image = path(['Image', 'data', 'attributes', 'url'], data) || '/images/vacancy.webp';
    const title = path(['Title'], data) || 'Развивай свою карьеру в&nbsp;METRO';

    return isFetching ? null : (
        <>
            <SectionBgImg
                title={title}
                img={image}
                backHide
                hero
            />
            <div className="container pt-[60px] pb-[148px]">
                <div className="flex max-lg:flex-col gap-[30px] w-full">
                    <InternalFilter
                        filters={filters}
                        setFilter={setFilter}
                        loadType={['VacancyIndustry', 'MetroHQAddressList']} />
                    <div className="flex-1">
                        <VacanciesList
                            filters={filters}
                            params={params}
                            line={Number(params.line) || 0}
                            objtype={disableSSO ? 'VacancyMetro' : 'InnerVacancyMetro'}
                            disabledNoVacancyLink
                            centralOffice={!!filters.centralOffice}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
