import React from 'react';
import Modal from 'react-modal';
import { useQuery } from 'react-query';
import { path } from 'ramda';
import { Link, useParams } from 'react-router-dom';
import Spin from 'antd/lib/spin';
import { slide as Slider } from 'react-burger-menu';

import { getVacancy, getVacancyStore, getVacancyContent } from '../queries';
import { ARCHIVE_VACANCY_CODE } from '../constants/dictionaries';
import { getVacancyTags } from '../utils/vacancy';

import { disableSSO } from '../components/layout/Layout';
import ItemMap from '../components/vacancies/ItemMap';
import VacancyModal from '../components/vacancies/VacancyModal';
import SectionBgImg from "../components/layout/SectionBgImg";
import BackButton from '../components/layout/BackButton'

const Vacancy = props => {
    const { id } = useParams();

    const { data : content = {}, isFetching } = useQuery(['vacancyContent'], getVacancyContent);
    const { isError, isSuccess, isLoading, data } = useQuery(['vacancy', id, disableSSO ? 'VacancyMetro' : 'InnerVacancyMetro'], getVacancy, {
      enabled: !!id,
    });

    const address = path(['metro', 'store'], data) || path(['metro', 'address'], data) || path(['address'], data) || null;

    const storeQuery = useQuery(['vacancyStore', address], getVacancyStore, {
        enabled: !!address,
    });
    const store = path(['data'], storeQuery);
    const item = path(['data', 0], store);
    const storeContacts = path(['data', 0, 'attributes', 'Contacts'], store);
    const storeDrivingMap = path(['data', 0, 'attributes', 'DrivingMap'], store);

    const [modalIsOpen, setIsOpen] = React.useState(false);
    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }

    const [modalFormIsOpen, setFormIsOpen] = React.useState(false);
    function openVacancyModal() {
        setFormIsOpen(true);
    }
    function closeVacancyModal() {
        setFormIsOpen(false);
    }

    const image = path(['Image', 'data', 'attributes', 'url'], content) || '/images/detail.jpeg';
    const title = path(['Title'], content) || 'Развивай свою карьеру в&nbsp;METRO';

    return (
        <>
            { isLoading && (
                <h2 className="text-center py-16 text-grey">Загрузка...</h2>
            )}
            { (isError || !id) && (
                <h2 className="text-center py-16 text-grey">Ошибка при загрузке вакансии</h2>
            )}
            { isSuccess && data.vacancyId && (
                <>
                    {data.vacancyStateId !== ARCHIVE_VACANCY_CODE ? (
                        <>
                            <div className="super super-blue">
                                { isFetching ? null : (
                                    <div className={`bg-blue flex relative min-h-[600px] rounded-b-20 overflow-hidden`}>
                                        <img src="/images/bg.svg" alt="" className="object-cover max-lg:object-left-top -translate-x-1/2 left-1/2 absolute inset-0 h-full" />
                                        <div className="container flex w-full !pr-0">
                                            <div className="grid lg:grid-cols-2 lg:gap-[30px] relative z-[2] w-full">
                                                <div className={`py-10 max-lg:min-h-[400px] max-lg:pr-5`}>
                                                    <BackButton btnBackClass='text-white' />
                                                    <div className='flex flex-col justify-center lg:h-full max-lg:mt-[60px] lg:-mt-10'>
                                                        <h1 className={`text-white max-md:text-[40px] md:max-lg:text-[48px] lg:text-[64px] font-medium font-display leading-[100%] tracking-wide max-md:mb-5 md:mb-[30px]`}>
                                                            {path(['vacancyName'], data) || path(['name'], data)}
                                                        </h1>
                                                        <div className="flex flex-wrap gap-[15px] pt-5">
                                                            { getVacancyTags(data).map(({ tag }, index) => tag && (
                                                                <span key={index} className="inline-flex p-2.5 rounded-10 bg-yellow max-lg:text-blue lg:text-black font-medium max-md:text-[12px] md:max-lg:text-[14px] lg:text-[16px] leading-[100%]">
                                                                {tag}
                                                            </span>
                                                            ))}
                                                        </div>
                                                        <a onClick={openVacancyModal} className="btn max-md:mt-10 md:mt-[60px] max-lg:w-full lg:px-16 justify-center">
                                                            Откликнуться
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className={`relative h-full overflow-hidden max-lg:-ml-5 max-md:h-[205px] md:max-lg:h-[490px]`}>
                                                    <ItemMap data={data} item={item} openModal={openVacancyModal} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="container flex max-lg:flex-col max-lg:gap-[60px] lg:gap-[30px] mt-[90px] max-md:mb-[100px] md:max-lg:mb-[120px] lg:mb-[140px]">
                                    <div className="desc flex-1">
                                        { data.offer && (
                                            <div className='border-t border-blue/40 max-lg:pt-5 lg:pt-[30px] max-lg:mb-10 lg:mb-[60px]'>
                                                <h2 className="title">Условия:</h2>
                                                <div className="pre-wrap" dangerouslySetInnerHTML={{ __html: data.offer }} />
                                            </div>
                                        )}
                                        { data.experience && (
                                            <div className='border-t border-blue/40 max-lg:pt-5 lg:pt-[30px] max-lg:mb-10 lg:mb-[60px]'>
                                                <h2 className="title">Требования:</h2>
                                                <div className="pre-wrap" dangerouslySetInnerHTML={{ __html: data.experience }} />
                                            </div>
                                        )}
                                        { data.responsibility && (
                                            <div className='border-t border-blue/40 max-lg:pt-5 lg:pt-[30px]'>
                                                <h2 className="title">Обязанности:</h2>
                                                <div className="pre-wrap" dangerouslySetInnerHTML={{ __html: data.responsibility }} />
                                            </div>
                                        )}
                                    </div>
                                    <div className="lg:sticky lg:top-[132px] lg:min-w-[430px] lg:max-w-[487px] lg:self-start">
                                        <div className="bg-[#E9EFFF] rounded-20 max-md:p-5 md:p-[30px] relative">
                                            <div className="text-black max-md:text-[34px] md:max-lg:text-[40px] lg:text-[46px] font-medium font-display leading-[100%] tracking-[0.4px]">МЫ ВЫПОЛНЯЕМ НАШИ ОБЯЗАТЕЛЬСТВА</div>
                                            <div className="flex flex-wrap gap-[15px] pt-5">
                                                { getVacancyTags(data).map(({ tag }, index) => tag && (
                                                    <span key={index} className="inline-flex p-2.5 rounded-10 bg-white text-blue font-medium max-md:text-[12px] md:max-lg:text-[14px] lg:text-[16px] leading-[100%]">
                                                    {tag}
                                                </span>
                                                ))}
                                            </div>
                                            {/* <div className="inline-flex mt-5">
                                                <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7 0C3.13 0 0 3.13 0 7C0 12.25 7 20 7 20C7 20 14 12.25 14 7C14 3.13 10.87 0 7 0ZM7 9.5C5.62 9.5 4.5 8.38 4.5 7C4.5 5.62 5.62 4.5 7 4.5C8.38 4.5 9.5 5.62 9.5 7C9.5 8.38 8.38 9.5 7 9.5Z" fill="white"/>
                                                </svg>
                                                <span className="pl-2 text-white">{address}</span>
                                            </div> */}
                                            {/* { !!storeDrivingMap && (
                                                <button className="mt-4 text-white underline opacity-80" onClick={openModal}>Схема проезда</button>
                                            )} */}
                                            <span className="btn btn-primary mt-10 w-full justify-center" onClick={openVacancyModal}>Откликнуться</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Slider
                                overlayClassName={"overlay-full"}
                                className={ "side-vacancy !z-[2200] top-0 bg-white max-lg:!w-full lg:!w-[785px]" }
                                right
                                push
                                burgerButtonClassName={ "hidden" }
                                bodyClassName={"sidebar-open"}
                                isOpen={!!modalFormIsOpen}
                                onStateChange={({ isOpen }) => !isOpen && closeVacancyModal()}
                                onClose={() => closeVacancyModal()}
                            >
                                <div className="head-side bg-transparent fixed top-0 z-10 w-full">
                                    <div className="bg-white max-md:h-[56px] md:h-[64px] lg:w-[760px]">
                                        <button className="absolute transition-none bg-white max-md:left-5 md:left-10 top-[30px] lg:top-6" onClick={() => closeVacancyModal()}>
                                            <svg
                                                className='max-md:size-4'
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                >
                                                <path
                                                    stroke="#1A3C7B"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M7.05 7.05l9.9 9.9M7.05 16.95l9.9-9.9"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div key={modalFormIsOpen} className="container min-h-0 max-md:px-5 md:px-10 max-md:pt-[86px] md:max-lg:pt-[106px] md:pt-[114px]">
                                    <VacancyModal id={id} />
                                </div>
                            </Slider>
                            <Modal
                                isOpen={modalIsOpen}
                                onRequestClose={closeModal}
                                contentLabel={address}
                                ariaHideApp={false}
                                className="modal"
                            >
                                <div className="modal-head">
                                    <h2 className="modal-title">Схема проезда</h2>
                                    <button className="absolute right-4 top-4 lg:right-9 lg:top-9" onClick={closeModal}>
                                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M11.5002 12.9142L1.70718 22.7072L0.292969 21.293L10.0859 11.5L0.293119 1.70718L1.70733 0.292969L11.5002 10.0858L21.293 0.292969L22.7072 1.70718L12.9144 11.5L22.7073 21.293L21.2931 22.7072L11.5002 12.9142Z" fill="#003D7C"/>
                                        </svg>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p>
                                        <svg className="mr-2 min-w-[24px] inline" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 2C8.13 2 5 5.13 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z" fill="#003D7C"/>
                                        </svg> {address}
                                    </p>
                                    <div className="flex items-start pb-3 leading-8">
                                        <svg className="mr-2 min-w-[24px]" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20.01 15.38C18.78 15.38 17.59 15.18 16.48 14.82C16.13 14.7 15.74 14.79 15.47 15.06L13.9 17.03C11.07 15.68 8.42 13.13 7.01 10.2L8.96 8.54C9.23 8.26 9.31 7.87 9.2 7.52C8.83 6.41 8.64 5.22 8.64 3.99C8.64 3.45 8.19 3 7.65 3H4.19C3.65 3 3 3.24 3 3.99C3 13.28 10.73 21 20.01 21C20.72 21 21 20.37 21 19.82V16.37C21 15.83 20.55 15.38 20.01 15.38Z" fill="#003D7C"/>
                                        </svg>
                                        <div dangerouslySetInnerHTML={{ __html : storeContacts }} />
                                    </div>
                                    <div className="whitespace-pre-wrap leading-8" dangerouslySetInnerHTML={{ __html : storeDrivingMap }} />
                                </div>
                            </Modal>
                        </>
                    ) : (
                        <h2>Вакансия в архиве</h2>
                    )}
                </>
            )}
        </>
    );
}

export default Vacancy;

