import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { path } from 'ramda';

import { getFilterUrlString } from "../../utils/filter";

const defaultPhones = [
    { Phone: '8-800-500-08-79' },
    { Phone: '+7-495-845-00-05' },
];

const defaultMenuAbout = [
    { Title: 'О компании', Link: 'https://rabota.metro-cc.ru/kompaniya' },
    { Title: 'Миссия и ценности', Link: 'https://rabota.metro-cc.ru/missiya' },
    { Title: 'Новости', Link: 'https://rabota.metro-cc.ru/novosti' },
    { Title: 'Контакты', Link: 'https://rabota.metro-cc.ru/kontakty' },
];

const defaultMenuVacancies = [
    { Title: 'Вакансии в ТЦ', Link: `/${getFilterUrlString({ type: 'store' })}` },
    { Title: 'Вакансии в офисе', Link: `/${getFilterUrlString({ type: 'office' })}` },
    { Title: 'Вакансии на складе', Link: `/${getFilterUrlString({ type: 'mgl' })}` },
];
const defaultMenuFooter = [
    { Title: 'Конфиденциальность', Link: 'https://rabota.metro-cc.ru/privacy' },
    { Title: 'Условия соглашения', Link: 'https://www.metro-cc.ru/copyright' },
    { Title: 'Cookies', Link: 'https://rabota.metro-cc.ru/cookies' },
];

export const defaultIcons = [
    {
        Title: 'VK', Link: 'https://vk.com/wearemetrocc',
        Image: { data: { attributes: { url: '/images/social/vk.svg' } } }
    },
    {
        Title: 'Youtube', Link: 'https://www.youtube.com/user/MetroCCru',
        Image: { data: { attributes: { url: '/images/social/youtube.svg' } } }
    },
    {
        Title: 'Inplace', Link: 'https://inplace.ru/company/METRO',
        Image: { data: { attributes: { url: '/images/social/inplace.svg' } } }
    },
];

export default function Footer({ footer, internships }) {
    const title = path(['Title'], footer) || 'Горячая линия Metro';
    const schedule = path(['Schedule'], footer) || 'Будни: с 7 до 22 часов<br/>Выходные: с 8 до 22 часов';
    const phones = path(['Phones'], footer) || defaultPhones;

    const titleAbout = path(['TitleAbout'], footer) || 'О компании';
    const linksAbout = path(['MenuAbout'], footer) || defaultMenuAbout;

    const titleVacancies = path(['TitleVacancies'], footer) || 'Соискателям';
    const linksVacancies = path(['MenuVacancies'], footer) || defaultMenuVacancies;

    const titleInternship = path(['TitleInternship'], footer) || 'Стажерские программы';
    const copyright = path(['Copyright'], footer) || '© METRO Cash and Carry Russia, 2022';
    const icons = path(['Icons'], footer) || defaultIcons;

    const links = path(['MenuFooter'], footer) || defaultMenuFooter;

    return (
        <>
            <footer className="footer bg-blue">
                <div className="container max-md:!px-0">
                    <div className="bg-white rounded-[20px] max-md:mb-5 md:my-10">
                        <div className="grid md:max-lg:grid-cols-2 lg:grid-cols-4 justify-between max-md:pt-5 md:max-lg:pt-10 max-lg:px-5 lg:px-10 lg:pt-10 lg:pb-[60px] md:gap-2">
                            <div className="max-md:pb-[50px] md:max-lg:pb-[60px] lg:pb-0">
                                <Link to="/">
                                    <svg
                                        className='max-md:h-[34px] md:max-lg:h-[42px]'
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="182"
                                        height="48"
                                        fill="none"
                                        viewBox="0 0 182 48"
                                        >
                                        <path
                                            fill="#1A3C7B"
                                            d="M175.2 0H6a6 6 0 00-6 6v36a6 6 0 006 6h169.2a6 6 0 006-6V6a6 6 0 00-6-6z"
                                        ></path>
                                        <path
                                            fill="#FFE500"
                                            d="M145.904 38.693a5.642 5.642 0 01-3.973-1.634 5.529 5.529 0 01-1.644-3.94V14.768c0-1.476.597-2.895 1.644-3.939a5.652 5.652 0 013.973-1.623h20.404c1.497 0 2.926.579 3.973 1.623a5.527 5.527 0 011.643 3.939V33.13a5.573 5.573 0 01-1.643 3.939 5.624 5.624 0 01-3.973 1.634h-20.404v-.011zm15.553-23.404h-10.703c-.63 0-1.159.522-1.159 1.146v15.028a1.15 1.15 0 001.159 1.146h10.703c.653 0 1.16-.5 1.16-1.146V16.435c0-.635-.507-1.146-1.16-1.146zM37.431 20.306V38.68h9.296V9.206H34.18L28.122 23.87 22.08 9.228H9.52v29.465h9.307V20.317l6.291 18.364h6.056M74.672 9.206H50.464v29.477h24.209v-6.084h-14.89v-5.8h14.89v-6.084h-14.89v-5.437h14.89M86.31 38.67h9.296V15.29h8.858V9.205H77.475v6.084h8.835m43.589 11.52l7.136 11.884h-10.67l-5.998-11.884h-3.804v11.884h-9.308V9.205h23.691c3.106 0 5.605 2.486 5.605 5.539v6.515c0 3.064-2.499 5.539-5.605 5.539h-1.035l-.012.011zm-2.903-11.52h-10.445v5.437h10.445c.641 0 1.159-.511 1.159-1.158v-3.122a1.15 1.15 0 00-1.159-1.146"
                                        ></path>
                                        </svg>
                                </Link>
                                <span className='text-black inline-flex max-md:text-xs md:text-base mt-[30px]'>{title}<br />{schedule}</span>
                                <div className='pt-5'>
                                    {phones.map((item, index) => (
                                        <div key={item.Phone}>
                                            <a href={`tel:${item.Phone.replace(/\-/g, '')}`} className='font-medium max-md:text-xs md:text-base leading-none text-dark inline-flex mb-[15px]'>
                                                {item.Phone}
                                            </a>
                                        </div>
                                    ))}
                                    <ul className="flex mt-[15px]">
                                        { (icons || []).map((item, index) => (
                                            <li className="pr-2.5" key={index}>
                                                <a href={item.Link} target='_blank' rel="noreferrer">
                                                    <img className='size-10' src={path(['Image', 'data', 'attributes', 'url'], item)} alt={item.Title} />
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            
                            <div className="max-md:pb-[50px] md:max-lg:pb-[60px] lg:pb-0">
                                <span className='max-md:text-sm md:text-lg font-medium uppercase max-md:leading-tight md:leading-[26px]'>{titleAbout}</span>
                                <ul className='pt-5'>
                                    {(linksAbout.length ? linksAbout : defaultMenuAbout).map(item => (
                                        <li className='mb-[15px]' key={item.Link}>
                                            <a href={item.Link} className='text-dark max-md:text-xs md:text-base hover:text-blue'>{item.Title}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="max-md:pb-[50px] md:max-lg:pb-[60px] lg:pb-0">
                                <span className='max-md:text-sm md:text-lg font-medium uppercase max-md:leading-tight md:leading-[26px]'>{titleVacancies}</span>
                                <ul className='pt-5'>
                                    {(linksVacancies.length ? linksVacancies : defaultMenuVacancies).map(item => (
                                        <li className='mb-[15px]' key={item.Link}>
                                            <a href={item.Link} className='text-dark max-md:text-xs md:text-base hover:text-blue'>{item.Title}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="max-md:pb-[50px] md:max-lg:pb-[60px] lg:pb-0">
                                <span className='max-md:text-sm md:text-lg font-medium uppercase max-md:leading-tight md:leading-[26px]'>{titleInternship}</span>
                                <ul className='pt-5'>
                                    { internships.map(item => (
                                        <li key={item.id} className='mb-[15px]'>
                                            <a href={path(['attributes', 'Link'], item)} className='text-dark max-md:text-xs md:text-base hover:text-blue'>
                                                {path(['attributes', 'Title'], item)}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="flex flex-col max-lg:px-5 lg:px-10">
                            <hr className="m-0 border-t border-blue/40"/>
                            <div className="flex justify-between align-baseline max-lg:justify-center md:text-center lg:items-center flex-col lg:flex-row py-5">
                                <div className="max-md:text-sm md:text-base lg:pb-0 max-lg:order-2 max-md:pt-2.5">{copyright} {new Date().getFullYear()}</div>
                                <ul className="flex max-md:flex-col max-lg:order-1 md:max-lg:flex-row max-lg:gap-x-[15px] lg:gap-x-[30px] md:max-lg:mx-auto">
                                    {(links.length ? links : defaultMenuFooter).map(item => (
                                        <li className='pb-2.5 lg:pb-0' key={item.Link}>
                                            <a href={item.Link} target="_blank" className='max-md:text-xs md:text-base text-dark underline hover:text-blue'> {item.Title}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}
