import React from 'react';
import cx from 'classnames';
import { Select } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { filterOption } from '../../../utils/filter';
import { is, pathOr } from 'ramda';

const Option = Select.Option;

const SelectWithDictionary = ({ action, valuePath, extraPath,  ...props }) => {
    const { data, status, isLoading } = action;
    const error = status === 'error';

    return (
        <div className={cx('dictionary-select', { error })}>
            <Select
                {...props}
                loading={isLoading}
                disabled={props.disabled || error || isLoading}
                filterOption={filterOption}
            >
                { status === 'success' && (is(Array, data) ? data : pathOr([], ['data'], data)).map(town => <Option extra={extraPath ? town[extraPath] : null} key={town.id} value={valuePath ? town[valuePath] : town.name}>
                    <div className='option-name'>
                        {town.name}
                    </div>
                </Option>)}
            </Select>
            { error && <ReloadOutlined type="reload" className='dictionary-error' onClick={action.refetch} /> }
        </div>
    );
}

export default SelectWithDictionary;
