import React from "react";
import { useNavigate } from "react-router-dom";

export default function BackButton({ btnBackClass,  }) {
    const navigate = useNavigate();

    return (
        <button type="button" onClick={() => navigate(-1)} className={`btn-back relative z-10 inline-flex w-fit items-center font-semibold p-1 opacity-50 hover:opacity-100 ${btnBackClass}`}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="none"
                viewBox="0 0 20 20"
                >
                <path
                    stroke="#1A3C7B"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15.833 10H4.167M10 4.167L4.167 10 10 15.833"
                ></path>
            </svg>
            <span className="pl-1">Назад</span>
        </button>
    );
}
