import React from 'react';
import { pathOr, is } from 'ramda';
import { useQuery } from 'react-query';

import { getDictionaryUrl } from '../../constants/urls';
import { getDictionary } from '../../queries';

const withLoadWrapper = WrappedComponent => function withLoad(props) {
    let data = {};

    if (is(Array, props.loadType)) {
        props.loadType.forEach(type => {
            const dictionaryUrl = getDictionaryUrl({ loadType: type });

            // eslint-disable-next-line
            data[type] = useQuery([type, dictionaryUrl], getDictionary);
        });
    } else {
        const dictionaryUrl = getDictionaryUrl({ loadType: props.loadType });

        // eslint-disable-next-line
        data = useQuery([props.loadType, dictionaryUrl], getDictionary);
    }

    return <WrappedComponent
        {...props}
        loadData={data}
        options={pathOr([], ['data'], data)}
        pending={data.isLoading} />;
};

export default withLoadWrapper;
