import React from "react";

import BackButton from './BackButton'

export default function SectionBgImg({ img, title, paragraph, bgColor, textColor, backColor, icon, paddingSection, titleLarge, btnLink, btnText, classText, hero, backHide }) {
    return (
        <>
            <div className={`bg-blue flex relative min-h-[356px] rounded-b-20 overflow-hidden ${bgColor} ${icon}`}>
                <img src="/images/bg.svg" alt="" className="object-cover max-lg:object-left-top -translate-x-1/2 left-1/2 absolute inset-0 h-full min-h-[564px]" />
                <div className="container flex w-full !pr-0">
                    <div className="grid lg:grid-cols-2 lg:gap-[30px] relative z-[2] w-full">
                        <div className={`py-10 max-lg:min-h-[400px] relative max-lg:pr-5 flex flex-col justify-center ${paddingSection}`}>
                            {!backHide && <div className="absolute top-5"><BackButton btnBackClass={`${backColor}`} /></div>}
                            <div>
                                { titleLarge &&
                                    <h1 dangerouslySetInnerHTML={{ __html : titleLarge }} className={`text-white max-md:text-[40px] md:max-lg:text-[48px] lg:text-[64px] font-medium font-display leading-[100%] tracking-wide max-md:mb-5 md:mb-[30px] ${textColor} ${classText}`} />
                                }
                                {title &&
                                    <h1 dangerouslySetInnerHTML={{ __html : title }} className={`text-white max-md:text-[40px] md:max-lg:text-[48px] lg:text-[64px] font-medium font-display leading-[100%] tracking-wide max-md:mb-5 md:mb-[30px] ${textColor} ${classText}`} />
                                }
                                <div dangerouslySetInnerHTML={{ __html : paragraph }} className={`lg:max-w-[700px] text-white text-base ${textColor} ${classText}`} />
                                {btnLink &&
                                    <a href={btnLink} className="btn mt-7 max-lg:w-full lg:px-16 justify-center">{btnText}</a>
                                }
                            </div>
                        </div>
                        {img &&
                            <div className={`relative h-full max-lg:-ml-5 max-md:h-[205px] md:max-lg:h-[490px] ${hero && "!max-h-[356px]"}`}>
                                <img src={img} alt="" className="object-cover w-full lg:object-center h-full lg:absolute" />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
