import React from 'react';
import ReactDOM from 'react-dom/client';
import moment from 'moment';
import 'moment/dist/locale/ru';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/es/locale/ru_RU';

import 'antd/dist/antd.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

moment.locale('ru');

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <ConfigProvider locale={ruRU}>
            <App />
        </ConfigProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
