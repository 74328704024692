import React from "react";
import { useQuery } from 'react-query';
import { path } from 'ramda';
import { Link } from 'react-router-dom';

import { getRecruitmentContent } from '../queries';
import SectionBgImg from "../components/layout/SectionBgImg";

export default function Recruitment() {
    const { data : content = {}, isFetching } = useQuery(['respondContent'], getRecruitmentContent);

    const image = path(['Image', 'data', 'attributes', 'url'], content) || '/images/recruitment.jpeg';
    const title = path(['Title'], content) || 'Процедура внутреннего рекрутмента';

    return (
        <>
            <SectionBgImg
                title={title}
                img={image}
                backColor="text-white"
            />
            <div className="container py-[11vh]">
                <div className="max-lg:p-4 lg:p-[30px] bg-white rounded-20 shadow-[0_0_15px_0_rgba(0,0,0,0.07)] mb-[11vh]">
                    <div className="grid lg:grid-cols-2 gap-10">
                        <div className="bg-blue rounded-20 py-[74px] max-lg:!px-5 pr-10 pl-[60px]">
                            <div className="font-display text-[46px] text-white leading-[100%] mb-10 font-medium tracking-[0.46px]">Публикация</div>
                            <p className="text-white max-lg:text-[16px] lg:text-[26px] leading-[140%] font-medium mb-3">Обязательная публикация всех открытых вакансий ЦО, ТЦ, МОЦО и&nbsp;MGL на&nbsp;внутреннем карьерном сайте METRO</p>
                            <Link to="/" className="btn bg-white px-16 justify-center">Откликнуться</Link>
                            <div>
                                <a href="http://internal.jobs.metro-cc.ru/" className="text-white underline max-lg:text-[14px] lg:text-[20px] mt-3 inline-flex leading-[140%]">http://internal.jobs.metro-cc.ru/</a>
                            </div>
                        </div>
                        <div className="self-center">
                            <div className="text-[26px] font-medium leading-[140%]">Исключения:</div>
                            <ul className="text-[20px] leading-[140%] list-disc pl-5 my-4">
                                <li>Вакансии NS2 и ниже</li>
                                <li>Конфиденциальный поиск</li>
                                <li>Наличие готового к&nbsp;назначению преемника, выявленного в&nbsp;рамках процедуры оценки потенциала (в&nbsp;этом случае публикация вакансии не&nbsp;обязательна,но рекомендуется для выявления более широкого круга потенциальных кандидатов)</li>
                                <li>Реструктуризация</li>
                                <li>Юридические ограничения</li>
                            </ul>
                            <p className="text-[18px] font-medium leading-[140%]">Исключения определяет HRBP подразделения</p>
                        </div>
                    </div>
                </div>
                <div className="border-t border-blue/40 py-[11vh]">
                    <div className="font-display text-[46px] leading-[100%] mb-10 font-medium tracking-[0.46px]">Отклик</div>
                    <div className="grid lg:grid-cols-3 gap-[60px]">
                        <div>
                            <div className="flex gap-2.5 items-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="48"
                                    fill="none"
                                    viewBox="0 0 22 48"
                                    >
                                    <path
                                        fill="#FFEC43"
                                        d="M21.777 23.458l-6.784-3.691a.44.44 0 00-.592.17l-2.09 3.762L.83 1.4a.426.426 0 01.192-.58L8.548.044a.438.438 0 01.585.2l12.75 23.311a.36.36 0 00-.106-.098z"
                                    ></path>
                                    <path
                                        fill="#FFE500"
                                        d="M21.944 24.044l-.22.377-6.796 3.425a.438.438 0 01-.588-.19L12.305 23.7l2.09-3.764a.441.441 0 01.592-.17l6.783 3.692a.359.359 0 01.117.094l.095.171a.424.424 0 01-.038.321z"
                                    ></path>
                                    <path
                                        fill="#FFEC43"
                                        d="M14.929 27.846l6.796-3.425L8.032 47.777a.431.431 0 01-.261.206.44.44 0 01-.333-.037L.226 46.352a.436.436 0 01-.209-.258.425.425 0 01.038-.328l12.251-22.058 2.035 3.956a.429.429 0 00.256.212.44.44 0 00.332-.03z"
                                    ></path>
                                </svg>
                                <span className="inline-flex text-[64px] font-medium leading-[140%] relative top-1.5">1</span>
                            </div>
                            <div className="text-[20px] leading-[140%] mt-5">Все отклики на
                                <div><a href="http://internal.jobs.metro-cc.ru/" className="text-blue underline text-[20px] inline-flex leading-[140%]">http://internal.jobs.metro-cc.ru/</a></div>
                            </div>
                        </div>
                        <div>
                            <div className="flex gap-2.5 items-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="48"
                                    fill="none"
                                    viewBox="0 0 22 48"
                                    >
                                    <path
                                        fill="#FFEC43"
                                        d="M21.777 23.458l-6.784-3.691a.44.44 0 00-.592.17l-2.09 3.762L.83 1.4a.426.426 0 01.192-.58L8.548.044a.438.438 0 01.585.2l12.75 23.311a.36.36 0 00-.106-.098z"
                                    ></path>
                                    <path
                                        fill="#FFE500"
                                        d="M21.944 24.044l-.22.377-6.796 3.425a.438.438 0 01-.588-.19L12.305 23.7l2.09-3.764a.441.441 0 01.592-.17l6.783 3.692a.359.359 0 01.117.094l.095.171a.424.424 0 01-.038.321z"
                                    ></path>
                                    <path
                                        fill="#FFEC43"
                                        d="M14.929 27.846l6.796-3.425L8.032 47.777a.431.431 0 01-.261.206.44.44 0 01-.333-.037L.226 46.352a.436.436 0 01-.209-.258.425.425 0 01.038-.328l12.251-22.058 2.035 3.956a.429.429 0 00.256.212.44.44 0 00.332-.03z"
                                    ></path>
                                </svg>
                                <span className="inline-flex text-[64px] font-medium leading-[140%] relative top-1.5">2</span>
                            </div>
                            <div className="text-[20px] leading-[140%] mt-5">
                            Прежде чем откликаться, сотрудник обязан уведомить своего непосредственного руководителя (для сотрудников ТЦ&nbsp;&mdash; еще дополнительно Управляющего&nbsp;ТЦ) о&nbsp;своих намерениях и&nbsp;получить его письменное подтверждение.
                            <div className="font-medium mt-2">Важно: руководитель не&nbsp;может запретить сотруднику откликнуться на&nbsp;вакансию,но может обсуждать с&nbsp;ним альтернативные возможности карьерного и&nbsp;профессионального развития.</div>
                            </div>
                        </div>
                        <div>
                            <div className="flex gap-2.5 items-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="48"
                                    fill="none"
                                    viewBox="0 0 22 48"
                                    >
                                    <path
                                        fill="#FFEC43"
                                        d="M21.777 23.458l-6.784-3.691a.44.44 0 00-.592.17l-2.09 3.762L.83 1.4a.426.426 0 01.192-.58L8.548.044a.438.438 0 01.585.2l12.75 23.311a.36.36 0 00-.106-.098z"
                                    ></path>
                                    <path
                                        fill="#FFE500"
                                        d="M21.944 24.044l-.22.377-6.796 3.425a.438.438 0 01-.588-.19L12.305 23.7l2.09-3.764a.441.441 0 01.592-.17l6.783 3.692a.359.359 0 01.117.094l.095.171a.424.424 0 01-.038.321z"
                                    ></path>
                                    <path
                                        fill="#FFEC43"
                                        d="M14.929 27.846l6.796-3.425L8.032 47.777a.431.431 0 01-.261.206.44.44 0 01-.333-.037L.226 46.352a.436.436 0 01-.209-.258.425.425 0 01.038-.328l12.251-22.058 2.035 3.956a.429.429 0 00.256.212.44.44 0 00.332-.03z"
                                    ></path>
                                </svg>
                                <span className="inline-flex text-[64px] font-medium leading-[140%] relative top-1.5">3</span>
                            </div>
                            <p className="text-[20px] leading-[140%] mt-5">Если вы&nbsp;хотите предложить сотруднику другого подразделения откликнуться на&nbsp;свою вакансию, обсудите предварительно это намерение с&nbsp;непосредственным руководителем этого сотрудника</p>
                        </div>
                    </div>
                    <div className="flex justify-center w-full mt-[11vh]">
                        <Link to="/" className="btn btn-primary px-16 justify-center text-[20px] uppercase py-6 h-auto max-w-[658px] w-full">Откликнуться</Link>
                    </div>
                </div>
                    <div className="font-display text-[46px] leading-[100%] mb-[6vh] font-medium tracking-[0.46px]">Рассмотрение</div>
                    <div className="grid lg:grid-cols-2 gap-[60px] border-t border-blue/40 pt-[30px]">
                        <div>
                            <div className="flex gap-2.5 items-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="48"
                                    fill="none"
                                    viewBox="0 0 22 48"
                                    >
                                    <path
                                        fill="#FFEC43"
                                        d="M21.777 23.458l-6.784-3.691a.44.44 0 00-.592.17l-2.09 3.762L.83 1.4a.426.426 0 01.192-.58L8.548.044a.438.438 0 01.585.2l12.75 23.311a.36.36 0 00-.106-.098z"
                                    ></path>
                                    <path
                                        fill="#FFE500"
                                        d="M21.944 24.044l-.22.377-6.796 3.425a.438.438 0 01-.588-.19L12.305 23.7l2.09-3.764a.441.441 0 01.592-.17l6.783 3.692a.359.359 0 01.117.094l.095.171a.424.424 0 01-.038.321z"
                                    ></path>
                                    <path
                                        fill="#FFEC43"
                                        d="M14.929 27.846l6.796-3.425L8.032 47.777a.431.431 0 01-.261.206.44.44 0 01-.333-.037L.226 46.352a.436.436 0 01-.209-.258.425.425 0 01.038-.328l12.251-22.058 2.035 3.956a.429.429 0 00.256.212.44.44 0 00.332-.03z"
                                    ></path>
                                </svg>
                                <span className="inline-flex text-[64px] font-medium leading-[140%] relative top-1.5">4</span>
                            </div>
                            <div className="text-[20px] leading-[140%] mt-5">
                            Руководитель, в&nbsp;чьем подразделении открыта вакансия, обязан провести собеседования со&nbsp;всеми внутренними кандидатами соответствующими опубликованным требованиям к&nbsp;вакансии, и&nbsp;дать им&nbsp;обратную связь
                            <div className="font-medium mt-7">Обратная связь даст сотруднику основу для развития в&nbsp;желаемом направлении</div>
                            </div>
                        </div>
                        <div>
                            <div className="flex gap-2.5 items-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="48"
                                    fill="none"
                                    viewBox="0 0 22 48"
                                    >
                                    <path
                                        fill="#FFEC43"
                                        d="M21.777 23.458l-6.784-3.691a.44.44 0 00-.592.17l-2.09 3.762L.83 1.4a.426.426 0 01.192-.58L8.548.044a.438.438 0 01.585.2l12.75 23.311a.36.36 0 00-.106-.098z"
                                    ></path>
                                    <path
                                        fill="#FFE500"
                                        d="M21.944 24.044l-.22.377-6.796 3.425a.438.438 0 01-.588-.19L12.305 23.7l2.09-3.764a.441.441 0 01.592-.17l6.783 3.692a.359.359 0 01.117.094l.095.171a.424.424 0 01-.038.321z"
                                    ></path>
                                    <path
                                        fill="#FFEC43"
                                        d="M14.929 27.846l6.796-3.425L8.032 47.777a.431.431 0 01-.261.206.44.44 0 01-.333-.037L.226 46.352a.436.436 0 01-.209-.258.425.425 0 01.038-.328l12.251-22.058 2.035 3.956a.429.429 0 00.256.212.44.44 0 00.332-.03z"
                                    ></path>
                                </svg>
                                <span className="inline-flex text-[64px] font-medium leading-[140%] relative top-1.5">5</span>
                            </div>
                            <p className="text-[20px] leading-[140%] mt-5">
                            Дата перевода внутреннего кандидата на&nbsp;новую должность обсуждается текущим руководителем работника и&nbsp;Нанимающим менеджером. При необходимости в&nbsp;диалоге участвует представитель&nbsp;HR, поддерживающий данное подразделение.<br /><br />
                            Перевод сотрудника на&nbsp;новую должность должен быть осуществлён не&nbsp;позднее 1&nbsp;месяца c&nbsp;даты подписания данным сотрудником предложения о&nbsp;работе на&nbsp;новой должности.<br /><br />
                            Исключения из&nbsp;данного правила обсуждаются при участии Дивизиональных менеджеров и&nbsp;Менеджера по&nbsp;персоналу или Регионального руководителя по&nbsp;персоналу
                            </p>
                        </div>
                    </div>
            </div>
        </>
    )
}
