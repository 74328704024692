import UrlPattern from 'url-pattern';

import { dictionariesTypes } from './dictionaries';

export const BASE_URL = `/SupportSrv/SupportSrv.svc/Support`;
export const BASE_API_URL = ``;

const construct = url => new UrlPattern('(*)' + url);

export const SUBMISSION = construct('/v1/metro/submit/anketa');
export const VACANCY = construct('/v1/metro/vacancy(/:id)');
export const DICTIONARIES = construct('/v1/landing/dictionary/:type');
export const METRO_DICTIONARIES = construct('/v1/metro/dictionary/:type');
export const METRO_INDUSTRY = construct('/v1/metro/industry/dictionary');

export const TOWN_DICTIONARY = construct('/v1/metro/town/dictionary');
export const STORE_DICTIONARY = construct('/v1/metro/store/dictionary');
export const MGL_DICTIONARY = construct('/v1/metro/address/dictionary');
export const ADDRESS_DICTIONARY = construct('/v1/metro/address/dictionary');
export const GET_FILE_LIMITS = construct('/v1/metro/limits');

export const API_STORE_DICTIONARY = construct('/api/shops');
export const API_INTERNSHIPS_DICTIONARY = construct('/api/internship-programs');
export const API_CONTENT_COMPONENT = construct('/api/internal/component/:type');
export const API_CONTENT_COMPONENT_PUBLIC = construct('/api/public/component/:type');
export const API_CONTENT_PAGE = construct('/api/internal/page/:type');

export const getDictionaryUrl = ({ loadType }) => {
    let url;

    switch (loadType) {
        case dictionariesTypes.education:
        case dictionariesTypes.YesNo:
        case dictionariesTypes.metroEducation:
        case dictionariesTypes.storeTowns:
        case dictionariesTypes.mglTowns:
        case dictionariesTypes.metroPotentialsEducation:
        case dictionariesTypes.townList:
        case dictionariesTypes.countryList:
        case dictionariesTypes.MetroHQAddressList:
        case dictionariesTypes.GRBusinessAreaActive:
            url = METRO_DICTIONARIES.stringify({ type: loadType });
            break;
        case dictionariesTypes.VacancyIndustry:
            url = METRO_INDUSTRY.stringify({ type: loadType });
            break;
        case dictionariesTypes.metroTowns:
            url = TOWN_DICTIONARY.stringify();
            break;
        case dictionariesTypes.limits:
            url = GET_FILE_LIMITS.stringify();
            break;
        default:
            url = DICTIONARIES.stringify({ type: loadType });
            break;
    }

    return url;
}

export const LOGIN_SSO = construct('/v2/login/sso');
export const CURRENT = construct('/v2/user/current/ext');
