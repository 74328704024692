import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { path } from 'ramda';

import { getLoginSSO, getLayoutContent } from '../../queries';
import { handleTokenChange } from '../../utils/token';

import ScrollToTop from './ScrollToTop';
import Header from './Header';
import Footer from './Footer';

export const disableSSO = (import.meta.env.MODE === 'development') && !import.meta.env.VITE_SSO;

export default function Layout({ children, hideFooter }) {
    const { isError, isSuccess } = useQuery(['sso'], getLoginSSO, {
        onSuccess: data => handleTokenChange(path(['token'], data)),
        onError: () => handleTokenChange(null),
        enabled: !disableSSO,
    });
    const { data = {}, isSuccess : isSuccessContent } = useQuery(['layoutContent'], getLayoutContent);

    return isSuccess || disableSSO ? (isSuccessContent ? (
        <>
            <ScrollToTop />
            <Header header={data.header} widgets={data.widgets} />
            <div id="__layout">
                <main className='main'>
                    {children}
                </main>
            </div>
            {!hideFooter && <Footer footer={data.footer} internships={data.internships} menu={data.menu} />}
        </>
    ) : '') : isError ? 'Не удалось авторизоваться в домене' : '';
}
