import React from "react";

export default function Success() {
  return (
      <>
          <div className="container py-56">
            <h2 className="pb-10 pt-6 font-['CA_Metro'] text-[34px] lg:text-[40px] font-black text-center">Анкета успешно отправлена</h2>
          </div>
      </>
  )
}
