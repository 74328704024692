import React, { useRef, useState, useEffect } from 'react';
import { path } from 'ramda';
import { Map, Placemark } from '@pbe/react-yandex-maps';
import ymapsTouchScroll from 'ymaps-touch-scroll';
import { useMediaQuery } from 'react-responsive';

const DEFAULT_CENTER = [55.75, 37.57];

const ItemMapComponent = ({ data, item, openModal }) => {
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const ref = useRef(null);
    const [templates, setTemplates] = useState({});
    const [bounded, setBounded] = useState(null);

    const address = path(['attributes', 'Address'], item);
    const bounds = item ? [
        path(['attributes', 'Location', 'Latitude'], item),
        path(['attributes', 'Location', 'Longitude'], item),
    ] : DEFAULT_CENTER;

    useEffect(() => {
        if (ref.current && !bounded) {
            ref.current.setCenter(bounds, 10);
            setBounded(bounds);
        }
    }, [bounds]);

    return (
        <>
            <Map
                instanceRef={instance => {
                    instance && ymapsTouchScroll(instance);
                    ref.current = instance;
                }}
                defaultState={{ center: bounds, zoom: item ? 14 : 9 }}
                options={{ suppressMapOpenBlock: true }}
                width="100%"
                height="100%"
                onLoad={(ymaps) => {
                    const templates = {
                        templateLayoutFactory: ymaps.templateLayoutFactory,
                        iconLayout: ymaps.templateLayoutFactory.createClass(
                            '<div style="color: #FFFFFF; font-weight: bold;">$[properties.iconContent]</div>'
                        ),
                        balloonContentLayout: ymaps.templateLayoutFactory.createClass(`
                            <div class="popover-content">
                                <h3 class="pt-1 max-md:text-sm max-md:leading-[120%] md:max-lg:text-[22px] lg:text-[26px] md:leading-[120%] font-medium font-['Gotham_Pro'] pr-10">${data.name}</h3>
                                <div class="flex mt-2 mb-3 pr-10">
                                    <svg
                                        class="min-w-[16px] mt-1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="18"
                                        fill="none"
                                        viewBox="0 0 16 18"
                                        >
                                        <path
                                            fill="#1A3C7B"
                                            d="M12 4.987a5.657 5.657 0 00-8 8l3.514 3.52a.667.667 0 00.947 0L12 12.954a5.634 5.634 0 000-7.967zM11.048 12l-3.046 3.06L4.954 12a4.307 4.307 0 116.093 0zM6.001 6.94a2.88 2.88 0 001.463 4.853 2.874 2.874 0 003.443-2.786 2.826 2.826 0 00-.84-2.033 2.867 2.867 0 00-4.066-.034zm3.126 3.12a1.553 1.553 0 11.447-1.086c-.01.411-.182.802-.48 1.086h.033z"
                                        ></path>
                                    </svg>
                                    <div class="max-md:text-xs max-md:leading-[120%] font-medium ml-2 md:max-lg:text-[18px] lg:text-[20px] md:leading-[140%]">$[properties.balloonContent]</div>
                                </div>
                                <div class="max-h-[180px] md:max-h-[200px] overflow-y-auto scroll-bar">
                                    <div id="vacancyModalBallon">
                                        <a class="btn btn-primary w-full justify-center">Откликнуться</a>
                                    </div>
                                </div>
                            </div>
                        `, {
                            build: function() {
                                templates.balloonContentLayout.superclass.build.call(this);
                                this.getParentElement().querySelector('#vacancyModalBallon a').addEventListener('click', openModal);
                            },
                            clear: function() {
                                this.getParentElement().querySelector('#vacancyModalBallon a').removeEventListener('click', openModal);
                                templates.balloonContentLayout.superclass.clear.call(this);
                            }
                        }),
                    };
                    setTemplates(templates);
                }}
            >
                { item && (
                    <Placemark
                        geometry={bounds}
                        properties={{
                            balloonContent: address,
                        }}
                        options={{
                            balloonShadow: false,
                            balloonContentLayout: templates.balloonContentLayout,
                            balloonPanelMaxMapArea: 0,
                            hideIconOnBalloonOpen: false,
                            balloonOffset: isMobile ? [-110, -60] : [-140, -75],
                            balloonMaxWidth: isMobile ? 260 : 400,
                            iconLayout: 'default#image',
                            iconImageHref: '/images/ballon.svg',
                            iconImageSize: [37, 48],
                            iconImageOffset: [-5, -38],
                            iconContentLayout: templates.iconLayout,
                        }}
                    />
                )}
            </Map>
        </>
    );
};

export default ItemMapComponent;
