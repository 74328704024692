export const OTHER = 'other';
export const ARCHIVE_VACANCY_CODE = 3;

export const EXPERIENCE = ['до 1 года', 'от 1 до 3 лет', 'от 3 до 5 лет', 'более 5 лет'];
export const EXPERIENCE_MONTHS = ['менее 1 месяца', 'от 1 месяца до 3 месяцев', 'от 3 и более'];
export const WANT_TEMP = ['Постоянная', 'Временная'];
export const WORK_SMENA = ['Полный день', 'Неполный день', 'Сменный график'];
export const DRIVING = [
    {
        id: undefined,
        name: 'Нет',
    },
    {
        id: true,
        name: 'Есть',
    }
];
export const INTERNSHIP_STRING = 'Стажировка';
export const INTERSHIP_WORK_TIME = ['20', '30', '40'];
export const INTERSHIP_WORKPLACE = [
    {
        id: 1,
        name:'Офис',
    }, {
        id: 2,
        name:'Торговый центр',
    }
];

export const INTERNSHIP_TOWNS = [
    'Москва',
    'Санкт-Петербург',
    'Новосибирск',
    'Екатеринбург',
    'Краснодар',
    'Казань',
];

export const INTERNSHIP_LANGUAGE_LEVEL = [
    'Beginner',
    'Pre-intermediate',
    'Intermediate',
    'Upper-Intermediate',
    'Advanced',
    'Fluent',
];

export const INTERNSHIP_DIRECTION = [
    'Sales',
    'Marketing\Ecom',
    'Buying',
    'Finance',
    'Supply chain',
];

export const dictionariesTypes = {
    education: 'MetroEducation',
    YesNo: 'YesNo',
    VacancyIndustry: 'VacancyIndustry',
    metroTowns: 'metroTowns',
    storeTowns: 'MetroTownListStore',
    mglTowns: 'MetroTownListMGL',
    metroEducation: 'MetroPplEduLevel',
    metroPotentialsEducation: 'MetroPotentialsEducation',
    townList: 'TownList',
    countryList: 'CountryList',
    MetroHQAddressList: 'MetroHQAddressList',
    limits: 'limits',
    GRBusinessAreaActive: 'GRBusinessAreaActive',
};

export const VALUTA = {
    '!RUR': 'руб.',
    'USD': 'долл.',
    'EUR': 'евро',
};

export const VALUTA_LOGO = {
    '!RUR': '₽',
    'USD': '$',
    'EUR': '€',
};

export const propfAreaClassifier = 'Функционал куда';

export const MALL_TYPE = 'ТЦ';
export const OFFICE_TYPE = 'ЦО';
export const MGL_TYPE = 'MGL';
