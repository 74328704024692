import axios from 'axios';
import { path } from 'ramda';

import * as urls from '../constants/urls';
import { dictionariesTypes } from '../constants/dictionaries';
import { LINES_PER_PAGE } from '../constants/list';

axios.defaults.baseURL = urls.BASE_URL;
axios.defaults.headers.common['Accept-Language'] = 'ru-RU,ru';

export const getLoginSSO = async () => {
    const { data } = await axios.get(urls.LOGIN_SSO.stringify());

    return data;
};

export const getCurrentUser = async () => {
    const { data } = await axios.get(urls.CURRENT.stringify());

    return data;
};

export const getProfessions = async () => {
    const { data } = await axios.get(urls.getDictionaryUrl({ loadType: dictionariesTypes.VacancyIndustry }), {
        params: {
            URLEncType: 'internal',
        }
    });

    return data;
};

export const getHQMetroList = async () => {
    const { data } = await axios.get(urls.getDictionaryUrl({ loadType: dictionariesTypes.MetroHQAddressList }));

    return data;
};

export const getStoreTowns = async () => {
    const { data } = await axios.get(urls.getDictionaryUrl({ loadType: dictionariesTypes.storeTowns }));

    return data;
};

export const getMglTowns = async () => {
    const { data } = await axios.get(urls.getDictionaryUrl({ loadType: dictionariesTypes.mglTowns }));

    return data;
};

export const getStores = async ({ queryKey }) => {
    const [key, city] = queryKey;
    const { data } = await axios.get(urls.ADDRESS_DICTIONARY.stringify(), {
        params: {
            URLEncPlaceStr: 'Store',
            URLEncTownStr: city,
        }
    });

    return data;
};

export const getMgls = async ({ queryKey }) => {
    const [key, city] = queryKey;
    const { data } = await axios.get(urls.ADDRESS_DICTIONARY.stringify(), {
        params: {
            URLEncPlaceStr: 'MGL',
            URLEncTownStr: city,
        }
    });

    return data;
};

export const getDictionary = async ({ queryKey }) => {
    const [key, url, params] = queryKey;
    if (!url) {
        return [];
    }

    const { data } = await axios.get(url, { params: params ? JSON.parse(params) : null });
    return data;
};

export const getVacancy = async ({ queryKey }) => {
    const [key, id, objtype] = queryKey;
    if (!id) {
        return {};
    }

    const { data } = await axios.get(urls.VACANCY.stringify({ id }), {
        params: {
            objtype,
        }
    });

    return data;
};

export const getVacancyStore = async ({ queryKey }) => {
    const [key, address] = queryKey;

    const { data } = await axios.get(urls.API_STORE_DICTIONARY.stringify(), {
        params: {
            'filters[Address][$eq]': address,
            'pagination[limit]': 1,
            'populate[0]': 'Location',
            'populate[1]': 'City',
        },
        baseURL: urls.BASE_API_URL,
    });

    return data;
};

export const getVacancyStores = async ({ queryKey = {} }) => {
    const [key, city] = queryKey;
    const { data } = await axios.get(urls.API_STORE_DICTIONARY.stringify(), {
        params: {
            'filters[type]': 'shop',
            'filters[City][Title][$eq]': city,
            'sort': 'ExternalId',
            'pagination[limit]': 1000,
        },
        baseURL: urls.BASE_API_URL,
    });
    return data;
};

export const getVacancyFormStore = async ({ queryKey }) => {
    const [key, code] = queryKey;

    const { data } = await axios.get(urls.API_STORE_DICTIONARY.stringify(), {
        params: {
            'filters[Address][$eq]': code,
            'pagination[limit]': 1000,
            populate: 'Location',
        },
        baseURL: urls.BASE_API_URL,
    });

    return data;
};

export const postVacancy = async (payload) => {
    const { data } = await axios.post(urls.SUBMISSION.stringify(), payload);
    return data;
};

export const getVacancies = async ({ queryKey }) => {
    const [key, payload] = queryKey;
    const { line, objtype, ...params } = payload ? JSON.parse(payload) : {};
    const { type = 'office', experience, work, schedule, profession, ...filters }= params.filters;

    switch (type) {
        case 'office':
            filters.centralOffice = true;
            filters.city = undefined;
            filters.storeCity = undefined;
        break;
        case 'store':
            filters.storeAddress = true;
            filters.city = undefined;
        break;
        case 'mgl':
            filters.mglAddress = true;
            filters.storeCity = undefined;
            filters.city = filters.city ? Number(filters.city) : undefined;
        break;
    }

    if (experience) {
        filters[experience] = true;
    }

    if (work) {
        filters[work] = true;
    }

    if (schedule) {
        filters[schedule] = true;
    }

    if (profession && Number(profession)) {
        filters.profFields = [{ code: Number(profession) }];
    }


    const { data } = await axios.post(urls.VACANCY.stringify(), filters, {
        params: {
            line,
            linesPerPage: LINES_PER_PAGE,
            objtype: objtype || 'VacancyMetro',
        }
    });

    return data;
}

export const getVacanciesNames = async ({ queryKey }) => {
    const [key, payload] = queryKey;
    const params = payload ? JSON.parse(payload) : {};

    const { data } = await axios.post(urls.VACANCY.stringify(), params, {
        params: {
            line: params.line,
            linesPerPage: LINES_PER_PAGE,
            objtype: params.objtype || 'VacancyMetro',
        }
    });

    return data;
}

export const getLayoutContent = async () => {
    const response = await Promise.all([
        axios.get(urls.API_CONTENT_COMPONENT.stringify({ type: 'header' }), {
            params: {
                populate: '*'
            },
            baseURL: urls.BASE_API_URL,
        }),
        axios.get(urls.API_CONTENT_COMPONENT.stringify({ type: 'menu' }), {
            params: {
                'populate[Menu][populate]': '*',
                'populate[Icons][populate]': '*',
            },
            baseURL: urls.BASE_API_URL,
        }),
        axios.get(urls.API_CONTENT_COMPONENT.stringify({ type: 'footer' }), {
            params: {
                'populate[Phones][populate]': '*',
                'populate[MenuAbout][populate]': '*',
                'populate[MenuVacancies][populate]': '*',
                'populate[MenuFooter][populate]': '*',
                'populate[Icons][populate]': '*',
            },
            baseURL: urls.BASE_API_URL,
        }),
        axios.get(urls.API_INTERNSHIPS_DICTIONARY.stringify(), {
            params: {
                'pagination[limit]': 1000,
                'populate[0]': 'Label',
                'populate[1]': 'Background',
            },
            baseURL: urls.BASE_API_URL,
        }),
        axios.get(urls.API_CONTENT_COMPONENT_PUBLIC.stringify({ type: 'header' }), {
            params: {
                'populate[HeaderWidget][populate]': '*',
                'populate[HeaderWidget.Image][populate]': '*',
            },
            baseURL: urls.BASE_API_URL,
        }),
    ]);

    return {
        header: path([0, 'data', 'data', 'attributes'], response) || {},
        menu: path([1, 'data', 'data', 'attributes'], response) || {},
        footer: path([2, 'data', 'data', 'attributes'], response) || {},
        internships: path([3, 'data', 'data'], response) || {},
        widgets: path([4, 'data', 'data', 'attributes', 'HeaderWidget'], response) || {},
    };
};

export const getMainContent = async () => {
    const response = await axios.get(urls.API_CONTENT_PAGE.stringify({ type: 'vacancies' }), {
        params: {
            populate: '*'
        },
        baseURL: urls.BASE_API_URL,
    });

    return path(['data', 'data', 'attributes'], response);
};

export const getVacancyContent = async () => {
    const response = await axios.get(urls.API_CONTENT_PAGE.stringify({ type: 'vacancy' }), {
        params: {
            populate: '*'
        },
        baseURL: urls.BASE_API_URL,
    });

    return path(['data', 'data', 'attributes'], response);
};

export const getRespondContent = async () => {
    const response = await axios.get(urls.API_CONTENT_PAGE.stringify({ type: 'respond' }), {
        params: {
            populate: '*'
        },
        baseURL: urls.BASE_API_URL,
    });

    return path(['data', 'data', 'attributes'], response);
};

export const getPoliticsContent = async () => {
    const response = await axios.get(urls.API_CONTENT_PAGE.stringify({ type: 'politics' }), {
        params: {
            'populate[FileFolder][populate]': '*',
        },
        baseURL: urls.BASE_API_URL,
    });

    return path(['data', 'data', 'attributes'], response);
};

export const getRecruitmentContent = async () => {
    const response = await axios.get(urls.API_CONTENT_PAGE.stringify({ type: 'recruitment' }), {
        params: {
            populate: '*'
        },
        baseURL: urls.BASE_API_URL,
    });

    return path(['data', 'data', 'attributes'], response);
};
