import { path } from 'ramda';
import moment from 'moment';

import { API_MONTH_YEAR_FORMAT, DATE_FORMAT, API_DATE_FORMAT } from '../constants/dateFormats';

export const getBirthDate = (birthDate) => birthDate ? (
    moment(birthDate, API_DATE_FORMAT).format(DATE_FORMAT)
) : (
    null
);

const commonMapper = values => ({
    ...values,
    phone: {
        code: values.phone.substr(0, values.phone.length - 10),
        number: values.phone.substr(values.phone.length - 10)
    },
    education: values.education ? [values.education] : null,
    wishes: values.wishes ? [{
        ...values.wishes,
        wantWorkplaceCode: values.wishes.wantWorkplaceCode || null,
    }] : null,
    birthDate: values.birthDate ? values.birthDate : null,
});

export const mapBeforeSubmit = values => ({
    ...commonMapper(values),
    employmentPlaces: values.employmentPlaces ? values.employmentPlaces.map(place => ({
        ...place,
        begin: path(['begin', 0], place) ? place.begin[0].format(API_MONTH_YEAR_FORMAT) : null,
        end: path(['begin', 1], place) ? place.begin[1].format(API_MONTH_YEAR_FORMAT) : null
    })) : null
});

export const mapBeforeSubmitPotentials = values => ({
    ...commonMapper(values),
    employmentPlaces: values.employmentPlaces ? [{
        begin: values.employmentPlaces[0].format(API_MONTH_YEAR_FORMAT),
        end: values.employmentPlaces[1].format(API_MONTH_YEAR_FORMAT)
    }] : null
});

const getIntervalTime = value => value ? (
    `${value[0].format('MM/YYYY')}`
    + (value[1] ? ` - ${value[1].format('MM/YYYY')}` : '')
) : null;

export const mapBeforeSubmitInternship = values => ({
    ...commonMapper(values),
    wishes: [{
        ...values.wishes,
        workTime: getIntervalTime(values.wishes.workTime),
    }],
});
