import React, { Component } from 'react';
import { Radio } from 'antd';
import { is } from 'ramda';

import 'antd/lib/radio/style/index.css';

import withFieldWrapper from '../../hocs/withFieldWrapper';
import withLoad from '../../hocs/withLoad';
import { OTHER } from '../../../constants/dictionaries';

class RadioButtons extends Component {
    static defaultProps = {
        options: []
    };

    onChange = e => {
        this.props.onChange(e.target.value);
    }

    renderOption = (option, index) => {
        const { input: { name }, other } = this.props;
        const isString = is(String, option);

        return isString ?
        <Radio.Button
            key={`${name}-${index}`}
            value={option}>
            { option }
        </Radio.Button> :
        <Radio.Button
            key={`${name}-${option.id}`}
            value={option.id}>
            { option.name }
            { option[OTHER] && other }
        </Radio.Button>
    }

    render() {
        const { input: { value }, options } = this.props;


        return <Radio.Group
            value={value || undefined}
            onChange={this.onChange}
            buttonStyle="solid">
            { options.map(this.renderOption)}
        </Radio.Group>
    }
}

export const LoadRadio = withLoad(withFieldWrapper(RadioButtons));

export default withFieldWrapper(RadioButtons);
